!<template>
  <div
    :class="[genderClass,nightmodeActive ? 'nightmode-brightness':'']"
    class="p-p-3 p-m-1 p-d-flex p-ai-center p-flex-column border-round"

  >
    <a v-if="hideAvatars" class="avatar" :href="profileUrl" target="_blank"
      ><img :src="avatarUrl" alt="avatar" class="avatar"
    /></a>
    <span class="font-bowlby">
      <template v-if="!hideAvatars">
        <a :href="profileUrl" target="_blank">{{ characterName }}</a>
      </template>
      <template v-else>
        {{ characterName }}
      </template>
    </span>
    <div>
      <span v-for="(att, index) in attributes" :key="att.attributeValue">
        <template v-if="att != noInfo">
          {{ att }}<span v-if="index != attributes.length - 1"> | </span>
        </template>
      </span>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, computed } from "vue";
import { normalizeGender } from "../ts/genderHelper";
import { useStore } from "../store/store";

import { getProfileUrl, getAvatarUrl } from "../ts/urlHelper";
export default defineComponent({
  name: "CharacterSummary",
  props: {
    characterName: {
      type: String,
      required: true,
    },
    characterGender: {
      type: String,
      required: true,
    },
    attributes: {
      type: Object as PropType<Array<string>>,
      required: true,
    },
  },
  components: {},
  setup(props) {
    const noInfo = "No Info";
    const store = useStore();

    return {
      noInfo,
      hideAvatars: computed(() => store.getOptions.value.hideAvatars),
      nightmodeActive: computed(() => store.getOptions.value.nightMode),
      genderClass: computed(() => normalizeGender(props.characterGender)),
      avatarUrl: computed(() => getAvatarUrl(props.characterName)),
      profileUrl: computed(() => getProfileUrl(props.characterName)),
    };
  },
});
</script>

<style lang="scss" scoped>
.border-round {
  border-radius: 4px;
}
</style>