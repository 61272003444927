<template>
  <div
      class="p-mb-1 relative break-words"
      :class="[
      !hideAvatars ? 'display-grid-no-ava' : 'display-grid',
      genderClass,
      nightmodeActive ? 'nightmode-brightness':''
    ]"
  >
    <div v-if="hideAvatars" class="left">
      <a class="avatar" :href="profileUrl" target="_blank"
      ><img :src="avatarUrl" alt="avatar" class="avatar"
      /></a>
    </div>
    <div class="right p-p-2">
      <span class="font-bowlby">
        <template v-if="!hideAvatars">
          <a :href="profileUrl" target="_blank">{{ ad.characterName }}</a>
        </template>
        <template v-else>
          {{ ad.characterName }}
        </template>

        <i
            v-if="['adlist', 'adsearch'].includes(mode)"
            class="p-pl-2 pi pi-eye-slash ignorecon"
            @click="pushIgnore()"
            v-tooltip.top="'Hide Ads/Status of this character'"
        ></i>
        <i
            v-if="['adlist', 'adsearch'].includes(mode)"
            class="p-pl-2 pi pi-heart favecon"
            @click="pushFavorite()"
            v-tooltip.top="'Save Ad to Favorites'"
        ></i>

        <i
            v-if="['favorites'].includes(mode)"
            class="p-pl-2 pi pi-times ignorecon"
            @click="dropFavorite(ad)"
            v-tooltip.top="'Remove Ad from Favorites'"
        ></i>
        <router-link :to="'/charsummary/'+ad.characterName" custom v-slot="{navigate}">
                  <i
                      class="p-pl-2 pi pi-list bluecon"
                      @click="navigate"
                      v-tooltip.top="'Go to Character Summary'"
                  ></i>
        </router-link>
      </span>
      <div class="p-pb-2">
        <span v-for="(att, index) in attributes" :key="att.attributeValue">
          <template v-if="att != noInfo">
            {{ att }}<span v-if="index != attributes.length - 1"> | </span>
          </template>
        </span>
      </div>
      <div
          ref="adroot"
          :class="[isExpanded ? 'flist-ad-expanded' : 'flist-ad']"
      >
        <b
        >{{ time }}
          <div
              v-html="ad.parsedMessage"
              :class="[hideEicons ? 'show-eicon' : 'hide-eicon']"
          ></div
          >
        </b>
      </div>
    </div>
    <div
        v-if="!isExpanded && overflows"
        class="conditional-dropdown"
        @click="isExpanded = true"
    >
      <i class="pi pi-angle-down"></i>
    </div>
  </div>
</template>

<script lang="ts">
import {computed, defineComponent, onMounted, PropType, ref} from "vue";
import {Ad} from "../store/state";
import {useStore} from "../store/store";
import {normalizeGender} from "../ts/genderHelper";
import {getAvatarUrl, getProfileUrl} from "../ts/urlHelper";
import {useToast} from "primevue/usetoast";
import ResizeObserver from "resize-observer-polyfill";

export default defineComponent({
  name: "ffa-ad",
  props: {
    ad: {
      type: Object as PropType<Ad>,
      required: true,
    },
    isFavorites: {
      type: Boolean,
      required: true,
    },
    mode: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    //Hacky stuff required to determine if the text is overflowing or not
    const adroot = ref<HTMLElement | null>(null);
    const clientHeight = ref(adroot.value?.clientHeight);
    const scrollHeight = ref(adroot.value?.scrollHeight);
    const isExpanded = ref<boolean>(false);
    const reassignDimensions = () => {
      clientHeight.value = adroot.value?.clientHeight;
      scrollHeight.value = adroot.value?.scrollHeight;
    };
    onMounted(() => {
      reassignDimensions();
      new ResizeObserver(reassignDimensions).observe(
          (adroot.value as unknown) as Element
      );
    });
    const overflows = computed(() => {
      if (clientHeight.value != null && scrollHeight.value != null) {
        return clientHeight.value < scrollHeight.value;
      } else {
        return false;
      }
    });

    const store = useStore();
    const noInfo = "No Info";
    const toast = useToast();
    const timeOnly = computed(() => store.getOptions.value.timeOnly);
    const nightmodeActive = computed(() => store.getOptions.value.nightMode);
    const time = computed(() => {
      const t = props.ad.timestamp ? new Date(props.ad.timestamp) : new Date();
      return timeOnly.value
          ? "[" + t.toLocaleTimeString() + "]"
          : "[" + t.toLocaleTimeString() + " - " + t.toLocaleDateString() + "]";
    });
    const pushIgnore = () => {
      const detailMsg =
          props.ad.characterName + " was added to Ignores. See Options.";
      toast.add({
        severity: "info",
        summary: "Ignore added",
        detail: detailMsg,
        life: 3000,
      });
      store.pushIgnore(props.ad.characterName);
    };
    const pushFavorite = () => {
      const detailMsg = "See favorites tab.";
      toast.add({
        severity: "success",
        summary: "Added to favorites.",
        detail: detailMsg,
        life: 3000,
      });
      store.pushFavorite(props.ad);
    };

    return {
      adroot,
      overflows,
      isExpanded,
      pushIgnore,
      pushFavorite,
      dropFavorite: store.removeFavorite,
      time,
      hideAvatars: computed(() => store.getOptions.value.hideAvatars),
      hideEicons: computed(() => store.getOptions.value.hideEicons),
      genderClass: normalizeGender(props.ad.characterGender),
      avatarUrl: getAvatarUrl(props.ad.characterName),
      profileUrl: getProfileUrl(props.ad.characterName),
      noInfo,
      nightmodeActive,
      attributes: computed(() => {
        return [
          props.ad.characterOrientation,
          props.ad.characterDomsub,
          props.ad.characterFurryPreference,
          ["adlist"].includes(props.mode) ? props.ad.channel : noInfo,
        ].filter((att) => att != noInfo);
      }),
    };
  },
});
</script>

<style lang="scss" scoped>
.conditional-dropdown {
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: center;
  bottom: 0;
  background: linear-gradient(rgba(8, 8, 16, 0), #080810);
  color: white;
  padding-bottom: 5px;
  cursor: pointer;
}

.conditional-dropdown:hover {
  background: linear-gradient(rgba(8, 8, 16, 0), #5f5f77);
}

.relative {
  position: relative;
}

.ignorecon {
  cursor: pointer;
  font-weight: bold;
}

.ignorecon:hover {
  cursor: pointer;
  color: rgb(207, 0, 0);
}

.favecon {
  cursor: pointer;
  font-weight: bold;
}

.favecon:hover {
  cursor: pointer;
  color: rgb(10, 255, 23);
}

.bluecon {
  cursor: pointer;
  font-weight: bold;
}

.bluecon:hover {
  cursor: pointer;
  color: rgb(10, 128, 255);
}

a:visited {
  color: blue;
}

.display-grid {
  display: grid;
  grid-template-columns: 70px 1fr;
  grid-auto-rows: minmax(100px, auto);
  border-radius: 4px;
}

.display-grid-no-ava {
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: minmax(100px, auto);
  border-radius: 4px;
}

.flist-ad {
  overflow: hidden;
  max-height: 190px;
}

.flist-ad-expanded {
  overflow: hidden;
}

.avatar {
  height: 55px;
  width: 55px;
}

.right {
  display: flex;
  flex-direction: column;
}

.left {
  display: flex;
  justify-content: center;
  padding-top: 15px;
}
</style>