!
<template>
  <div
      class="nav-button p-text-bold"
      :class="
      isActive ? 'border-bottom-active' : 'border-bottom'
    "
  >
    {{ displayText }}
  </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";

export default defineComponent({
  name: "NavButton",
  props: {
    displayText: {
      type: String,
      required: true,
    },
    isActive: {
      type: Boolean,
      required: true,
    }
  },
  components: {},
  setup() {
    return {};
  },
});
</script>

<style lang="scss" scoped>
.nav-button {
  user-select: none;
  padding: 15px;
  background-color: #212121;
  cursor: pointer;
  color: grey;
}

.nav-button:hover {
  color: white;

}

.border-bottom-active {
  color: white;
  border-bottom: 4px solid white;
}
</style>