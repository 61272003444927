import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import PrimeVue from 'primevue/config';
import WebsocketConnectionHandler from "./ts/websocket";

import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import InputText from 'primevue/inputtext';
import Listbox from 'primevue/listbox';
import Card from 'primevue/card';
import ToggleButton from 'primevue/togglebutton';
import Chip from 'primevue/chip';
import Paginator from 'primevue/paginator';
import ProgressBar from 'primevue/progressbar';
import MultiSelect from 'primevue/multiselect';
import Toast from 'primevue/toast';
import ToastService from 'primevue/toastservice';
import Message from 'primevue/message';
import Tooltip from 'primevue/tooltip';
import Sidebar from 'primevue/sidebar';
import Checkbox from 'primevue/checkbox';
import Timeline from 'primevue/timeline';
import OverlayPanel from 'primevue/overlaypanel';
import InputNumber from 'primevue/inputnumber';

import 'primevue/resources/themes/saga-blue/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';

createApp(App)
    .component('Button', Button)
    .component('Dialog', Dialog)
    .component('Sidebar', Sidebar)
    .component('OverlayPanel', OverlayPanel)
    .component('Message', Message)
    .component('Checkbox', Checkbox)
    .component('InputText', InputText)
    .component('InputNumber', InputNumber)
    .component('Listbox', Listbox)
    .component('ToggleButton', ToggleButton)
    .component('Card', Card)
    .component('Chip', Chip)
    .component('ProgressBar', ProgressBar)
    .component('Paginator', Paginator)
    .component('MultiSelect', MultiSelect)
    .component('Timeline', Timeline)
    .component('Toast', Toast)
    .component('DataTable', DataTable)
    .component('Column', Column)
    .directive('tooltip', Tooltip)
    .use(router)
    .use(PrimeVue)
    .use(ToastService)
    .mount('#app')
new WebsocketConnectionHandler().handleWebsocketConnection();