<template>
  <Dialog
      header="Save AdSearch"
      v-model:visible="visibilityRef"
      :modal="true"
      :contentStyle="{ overflow: 'auto' }"
  >
    <div class="p-text-center p-pt-2 p-d-flex p-jc-center">
      <span>
        Enter a name under which you want to save your currently selected AdSearch.<br>
      </span>
    </div>
    <div class="p-d-flex p-jc-center p-mt-2">
      <InputText type="text" v-model="adSearchName" placeholder="Enter AdSearch name"/>
    </div>
    <template #footer>
      <Button
          label="Cancel"
          icon="pi pi-times"
          @click="cancel()"
          class="p-button-text"
      />
      <Button
          :disabled="!adSearchName"
          label="Save"
          icon="pi pi-check-square"
          @click="save()"
          class="p-button-text"
          autofocus
      />
    </template>
  </Dialog>
</template>

<script lang="ts">
import {computed, defineComponent, ref, Slots} from "vue";

interface SetupContext {
  slots: Slots;
  emit: (event: string, ...args: unknown[]) => void;
}

export default defineComponent({
  name: "AdSearchSaveDialog",
  components: {},
  props: {
    visibility: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, context: SetupContext) {
    const adSearchName = ref("");
    const visibilityRef = computed({
      get: () => props.visibility,
      set: (newVisibility: boolean) => {
        context.emit("update:visibility", newVisibility);
      },
    });

    const cancel = () => {
      context.emit("update:visibility", false);
    };


    const save = () => {
      context.emit("save", adSearchName.value);
      cancel()
    };

    return {
      visibilityRef,
      cancel,
      adSearchName,
      save
    };
  },
});
</script>

<style>

</style>