import {Ad, FilterSet, SharedMessageValues, Status} from '../store/state'
import {Options} from '../store/store'

interface FilterValue {
    label: string;
    value: string;
}

export function getFilterChannel(): Array<FilterValue> {
    return [
        {label: 'Ageplay', value: 'Ageplay'},
        {label: 'All in the Family', value: 'All in the Family'},
        {label: 'Artists / Writers', value: 'Artists / Writers'},
        {label: 'Ass Play', value: 'Ass Play'},
        {label: 'Avians', value: 'Avians'},
        {label: 'Bondage', value: 'Bondage'},
        {label: 'Canon Characters OOC', value: 'Canon Characters OOC'},
        {label: 'Cum Lovers', value: 'Cum Lovers'},
        {label: 'Cuntboys', value: 'Cuntboys'},
        {label: 'Diapers/Infantilism', value: 'Diapers/Infantilism'},
        {label: 'Domination/Submission', value: 'Domination/Submission'},
        {label: 'Dragons', value: 'Dragons'},
        {label: 'Equestria ', value: 'Equestria '},
        {label: 'Fantasy', value: 'Fantasy'},
        {label: 'Fat and Pudgy', value: 'Fat and Pudgy'},
        {label: 'Femboy', value: 'Femboy'},
        {label: 'Femdom', value: 'Femdom'},
        {label: 'Ferals / Bestiality', value: 'Ferals / Bestiality'},
        {label: 'Footplay', value: 'Footplay'},
        {label: 'Force/Non-Con', value: 'Force/Non-Con'},
        {label: 'Furries', value: 'Furries'},
        {label: 'Gay Furry Males', value: 'Gay Furry Males'},
        {label: 'Gay Males', value: 'Gay Males'},
        {label: 'German Furry', value: 'German Furry'},
        {label: 'German OOC', value: 'German OOC'},
        {label: 'Gore', value: 'Gore'},
        {label: 'Hermaphrodites', value: 'Hermaphrodites'},
        {label: 'Humans/Humanoids', value: 'Humans/Humanoids'},
        {label: 'Hyper Endowed', value: 'Hyper Endowed'},
        {label: 'Inflation', value: 'Inflation'},
        {label: 'Latex', value: 'Latex'},
        {label: 'Lesbians', value: 'Lesbians'},
        {label: 'Love and Affection', value: 'Love and Affection'},
        {label: 'Medical Play', value: 'Medical Play'},
        {label: 'Micro/Macro', value: 'Micro/Macro'},
        {label: 'Mind Control', value: 'Mind Control'},
        {label: 'Monster\'s Lair', value: 'Monster\'s Lair'},
        {label: 'Muscle Bound', value: 'Muscle Bound'},
        {label: 'Non-Sexual RP', value: 'Non-Sexual RP'},
        {label: 'Para/Multi-Para RP', value: 'Para/Multi-Para RP'},
        {label: 'Pokefurs', value: 'Pokefurs'},
        {label: 'Pregnancy and Impregnation', value: 'Pregnancy and Impregnation'},
        {label: 'RP Bar', value: 'RP Bar'},
        {label: 'RP Dark City', value: 'RP Dark City'},
        {label: 'Sadism/Masochism', value: 'Sadism/Masochism'},
        {label: 'Sadism/Masochism', value: 'Sadism/Masochism'},
        {label: 'Scat Play', value: 'Scat Play'},
        {label: 'Sci-fi', value: 'Sci-fi'},
        {label: 'Sex Driven LFRP', value: 'Sex Driven LFRP'},
        {label: 'Story Driven LFRP', value: 'Story Driven LFRP'},
        {label: 'Straight Roleplay', value: 'Straight Roleplay'},
        {label: 'Superheroes', value: 'Superheroes'},
        {label: 'The Slob Den', value: 'The Slob Den'},
        {label: 'Transformation', value: 'Transformation'},
        {label: 'Transgender', value: 'Transgender'},
        {label: 'Vore', value: 'Vore'},
        {label: 'Warhammer General', value: 'Warhammer General'},
        {label: 'Watersports', value: 'Watersports'},
        {label: 'World of Warcraft', value: 'World of Warcraft'},
    ]
}

function passesAgeLimits(smv: SharedMessageValues, filter: FilterSet): boolean {
    if (filter.maxAge || filter.minAge) {
        if (!smv.characterAge) {
            return false
        }
        if (filter.minAge && smv.characterAge < filter.minAge) {
            return false
        }
        if (filter.maxAge && smv.characterAge > filter.maxAge) {
            return false
        }
    }
    return true
}

function passesKinkFilters(status: SharedMessageValues, filter: FilterSet): boolean {
    for (const selectedKink of filter.selectedKinks) {
        for (const kinkId of selectedKink.kinkIds) {
            if (selectedKink.fave && status.faveKinks.has(kinkId)) {
                continue
            }
            if (selectedKink.yes && status.yesKinks.has(kinkId)) {
                continue
            }
            if (selectedKink.maybe && status.maybeKinks.has(kinkId)) {
                continue
            }
            if (selectedKink.no && status.noKinks.has(kinkId)) {
                continue
            }
            return false
        }
    }
    return true;
}

function passesSharedFilters(status: SharedMessageValues, filter: FilterSet): boolean {
    return (filter.selectedBodytypes.has(status.characterBodytype) || filter.selectedBodytypes.size == 0)
        && (filter.selectedDomSub.has(status.characterDomsub) || filter.selectedDomSub.size == 0)
        && (filter.selectedGenders.has(status.characterGender) || filter.selectedGenders.size == 0)
        && (filter.selectedHumanFurryPref.has(status.characterFurryPreference) || filter.selectedHumanFurryPref.size == 0)
        && (filter.selectedOrientations.has(status.characterOrientation) || filter.selectedOrientations.size == 0)
        && (filter.selectedLanguagePreference.has(status.characterLanguagePreference) || filter.selectedLanguagePreference.size == 0)
        && (filter.selectedPosition.has(status.characterPosition) || filter.selectedPosition.size == 0)
        && passesAgeLimits(status, filter)
        && passesKinkFilters(status, filter)
}


function adPassesPremiere(premiere: string): boolean {
    const date1: Date = new Date()
    const date2: Date = new Date(premiere)
    const timeInMilisec: number = date1.getTime() - date2.getTime()
    const daysBetweenDates: number = Math.ceil(timeInMilisec / (1000 * 60 * 60 * 24))
    return daysBetweenDates < 7
}

function adPassesFilter(ad: Ad, filter: FilterSet): boolean {
    return passesSharedFilters(ad, filter)
        && (filter.selectedChannel.has(ad.channel) || filter.selectedChannel.size == 0)
}

export function adPassesFilters(ad: Ad, filters: Array<FilterSet>, ignoreSet: Set<string>, options: Options): boolean {
    if (ignoreSet.has(ad.characterName)) return false
    if (options.freshAdsOnly && !adPassesPremiere(ad.premiere)) {
        return false
    }
    for (const filter of filters.filter(f => !f.paused)) {
        if (adPassesFilter(ad, filter)) return true
    }
    return filters.filter(f => !f.paused).length == 0
}

export function statusPassesFilters(status: Status, filters: Array<FilterSet>, ignoreSet: Set<string>): boolean {
    if (ignoreSet.has(status.characterName)) return false
    for (const filter of filters.filter(f => !f.paused)) {
        if (passesSharedFilters(status, filter)) return true
    }
    return filters.filter(f => !f.paused).length == 0
}


export function filterStatusList(status: Array<Status>, filters: Array<FilterSet>, ignoreSet: Set<string>): Array<Status> {
    const filteredStatus: Array<Status> = []
    for (let i = 0; i < status.length; i++) {
        if (filteredStatus.length > 150) break;
        if (statusPassesFilters(status[i], filters, ignoreSet)) {
            filteredStatus.push(status[i])
        }
    }
    return filteredStatus
}

export function filterAdList(ads: Array<Ad>, filters: Array<FilterSet>, ignoreSet: Set<string>, options: Options): Array<Ad> {
    const filteredAds: Array<Ad> = []
    const hashDuplicates: Set<string> = new Set<string>()
    for (let i = 0; i < ads.length; i++) {
        if (filteredAds.length > 150) break;
        if (adPassesFilters(ads[i], filters, ignoreSet, options) && !hashDuplicates.has(ads[i].adHash)) {
            filteredAds.push(ads[i])
            if (options.filterDuplicates) {
                hashDuplicates.add(ads[i].adHash)
            }
        }
    }
    return filteredAds;
}