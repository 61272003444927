import {createRouter, createWebHistory, RouteRecordRaw} from 'vue-router'
import AdView from "@/views/AdView.vue";
import StatusView from "@/views/StatusView.vue";
import AdSearch from "@/views/AdSearch.vue";
import CharacterSummaryView from "@/views/CharacterSummaryView.vue";
import FavoritesView from "@/views/FavoritesView.vue";
import OptionsView from "@/views/OptionsView.vue";
import FaqView from "@/views/FaqView.vue";

const routes: Array<RouteRecordRaw> = [
    {
        name: "adlist",
        path: "/",
        component: AdView
    },
    {
        name: "statuslist",
        path: "/statuslist",
        component: StatusView
    },
    {
        name: "adsearch",
        path: "/adsearch",
        component: AdSearch
    },
    {
        name: "charsummary",
        path: "/charsummary/:charname?",
        props: true,
        component: CharacterSummaryView,
    },
    {
        name: "favorites",
        path: "/favorites",
        component: FavoritesView
    },
    {
        name: "options",
        path: "/options",
        component: OptionsView
    },
    {
        name: "faq",
        path: "/faq",
        component: FaqView
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
