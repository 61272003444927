import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createBlock as _createBlock, createVNode as _createVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  key: 0,
  class: "p-d-flex p-jc-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Ad = _resolveComponent("Ad")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", {
      class: _normalizeClass(["p-d-flex p-ai-center p-flex-column", [_ctx.options.nightMode ? 'night-text':'day-text' ]])
    }, [
      _cache[2] || (_cache[2] = _createElementVNode("h1", { class: "p-pb-3 font-bowlby" }, "Favorites", -1)),
      (_ctx.faves.length == 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, _cache[1] || (_cache[1] = [
            _createTextVNode(" You have no favorite ads. You can select some by clicking the "),
            _createElementVNode("i", { class: "pi pi-heart" }, null, -1),
            _createTextVNode(" icon on an ad. ")
          ])))
        : _createCommentVNode("", true)
    ], 2),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.faves, (ad) => {
      return (_openBlock(), _createBlock(_component_Ad, {
        class: "p-shadow-2",
        key: ad.key,
        ad: ad,
        mode: "favorites"
      }, null, 8, ["ad"]))
    }), 128)),
    (_ctx.faves.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_Button, {
            class: "p-mt-2 p-button-danger",
            label: "Clear all Favorites",
            icon: "pi pi-trash",
            iconPos: "left",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.clearFavorites()))
          })
        ]))
      : _createCommentVNode("", true)
  ]))
}