import { createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "p-d-flex p-jc-center p-p-5 p-flex-wrap" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Card = _resolveComponent("Card")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Card, {
      class: _normalizeClass(["p-m-2", [_ctx.options.nightMode ? 'night':'day' ]]),
      style: {"width":"30rem","margin-bottom":"2em"}
    }, {
      title: _withCtx(() => _cache[0] || (_cache[0] = [
        _createTextVNode(" What is this about? ")
      ])),
      content: _withCtx(() => _cache[1] || (_cache[1] = [
        _createElementVNode("p", null, [
          _createTextVNode(" This app is allowing you to filter ads for roleplays from the "),
          _createElementVNode("a", {
            target: "_blank",
            href: "https://www.f-list.net"
          }, "F-List"),
          _createTextVNode(" website. The designated roleplay channels are usually very spammy and depending on what you are looking for most of the ads are of no interest to you. FFA allows you to blend these out by using filters, making it easier to find those ads you could actually be interested in. With the AdSearch it also offers you to retroactively check ads posted in the past 72 hours. TL;DR hopefully this helps you find roleplay partners more easily! ")
        ], -1)
      ])),
      _: 1
    }, 8, ["class"]),
    _createVNode(_component_Card, {
      class: _normalizeClass(["p-m-2", [_ctx.options.nightMode ? 'night':'day' ]]),
      style: {"width":"30rem","margin-bottom":"2em"}
    }, {
      title: _withCtx(() => _cache[2] || (_cache[2] = [
        _createTextVNode(" How does the AdList work? ")
      ])),
      content: _withCtx(() => _cache[3] || (_cache[3] = [
        _createElementVNode("p", null, " In the 'Adlist' section you will get a live reposting on all roleplay ads being posted in the Sex Driven - or Story Driven LFRP channels in the F-List chat. Only ads that match at least one of your filters (if you have any in place) will get displayed. Older ads will at some point 'drop off the bottom of the list' with new ones always being added to the top. ", -1)
      ])),
      _: 1
    }, 8, ["class"]),
    _createVNode(_component_Card, {
      class: _normalizeClass(["p-m-2", [_ctx.options.nightMode ? 'night':'day' ]]),
      style: {"width":"30rem","margin-bottom":"2em"}
    }, {
      title: _withCtx(() => _cache[4] || (_cache[4] = [
        _createTextVNode(" What does show fresh ads only mean? ")
      ])),
      content: _withCtx(() => _cache[5] || (_cache[5] = [
        _createElementVNode("p", null, " There is a lot of 'noise' in the ad channels coming from characters posting the exact same ad for weeks, months (years?!). This new option helps filtering all these out with one click. When this AdList option is checked, only ads that premiered (were posted for the first time) no longer than a week ago will be shown. All other active filters apply on top of this selection. ", -1)
      ])),
      _: 1
    }, 8, ["class"]),
    _createVNode(_component_Card, {
      class: _normalizeClass(["p-m-2", [_ctx.options.nightMode ? 'night':'day' ]]),
      style: {"width":"30rem","margin-bottom":"2em"}
    }, {
      title: _withCtx(() => _cache[6] || (_cache[6] = [
        _createTextVNode("Why are some Ads 'jumping' to the top of the AdList?")
      ])),
      content: _withCtx(() => _cache[7] || (_cache[7] = [
        _createElementVNode("p", null, " This is a combination of using the 'Filter Duplicates' option and people posting the same ad in quick succession in multiple different channels. If you filter duplicates, the same ad posted multiple times will only show up in your AdList once (from the channel it was last posted). If somebody now posts the same Ad with a 2 second delay in multiple channels, each time he does so it will be shown at the top of the list as a new entry while the old (now duplicate) Ad entry is removed. This creates that 'jumping' effect, when that happens you will see the channel where the Ad was posted constantly change around. ", -1)
      ])),
      _: 1
    }, 8, ["class"]),
    _createVNode(_component_Card, {
      class: _normalizeClass(["p-m-2", [_ctx.options.nightMode ? 'night':'day' ]]),
      style: {"width":"30rem","margin-bottom":"2em"}
    }, {
      title: _withCtx(() => _cache[8] || (_cache[8] = [
        _createTextVNode(" How does the StatusList work? ")
      ])),
      content: _withCtx(() => _cache[9] || (_cache[9] = [
        _createElementVNode("p", null, " The 'Statuslist' is a live display of all characters who are online in the chat, have their status set to 'looking' and match at least one of your filters (if you have any in place). The list is kept up to date automatically, with characters that go offline or change their status away from 'looking', will disappear from the list. A maximum of 150 characters is displayed. ", -1)
      ])),
      _: 1
    }, 8, ["class"]),
    _createVNode(_component_Card, {
      class: _normalizeClass(["p-m-2", [_ctx.options.nightMode ? 'night':'day' ]]),
      style: {"width":"30rem","margin-bottom":"2em"}
    }, {
      title: _withCtx(() => _cache[10] || (_cache[10] = [
        _createTextVNode(" How does the AdSearch work? ")
      ])),
      content: _withCtx(() => _cache[11] || (_cache[11] = [
        _createElementVNode("p", null, " With the ad search you can find ads posted in the past 72 hours filtered by the same attributes as for the Lists plus the possibility to also include one or several keywords that have to appear in the ad. Any keywords mode means that an ad will pass if it includes one or more of the keywords you selected. All keywords mode means that an ad will pass if all the keywords you have selected are appearing in it. Keywords are case insensitive and have to be separated by comma. ", -1)
      ])),
      _: 1
    }, 8, ["class"]),
    _createVNode(_component_Card, {
      class: _normalizeClass(["p-m-2", [_ctx.options.nightMode ? 'night':'day' ]]),
      style: {"width":"30rem","margin-bottom":"2em"}
    }, {
      title: _withCtx(() => _cache[12] || (_cache[12] = [
        _createTextVNode(" How does the Character Summary work? ")
      ])),
      content: _withCtx(() => _cache[13] || (_cache[13] = [
        _createElementVNode("p", null, " The character summary allows you to find past ads or looking status (with text) for a specific character. Just type in the (exact) name of the character and see if they were looking for something you like! A maximum of 15 results each are displayed. ", -1)
      ])),
      _: 1
    }, 8, ["class"]),
    _createVNode(_component_Card, {
      class: _normalizeClass(["p-m-2", [_ctx.options.nightMode ? 'night':'day' ]]),
      style: {"width":"30rem","margin-bottom":"2em"}
    }, {
      title: _withCtx(() => _cache[14] || (_cache[14] = [
        _createTextVNode(" Who to contact for questions, feedback, requests? ")
      ])),
      content: _withCtx(() => _cache[15] || (_cache[15] = [
        _createElementVNode("p", null, [
          _createTextVNode(" Leon Strasky via Chat / Notes or FFA Bot via Notes only. FFA Bot is the bot character who should be online 24/7 in the chat. He's not very talkactive but you can issue a few commands to him. For a list of available commands, PM him with !commands. Feedback is "),
          _createElementVNode("b", null, "always"),
          _createTextVNode(" apprechiated so anything that you dislike about the app or think would work better differently. Let me know per note to "),
          _createElementVNode("a", {
            href: "https://www.f-list.net/c/FFA%20Bot/",
            target: "_blank"
          }, "FFA Bot"),
          _createTextVNode(" or directly to "),
          _createElementVNode("a", {
            href: "https://www.f-list.net/c/Leon%20Strasky",
            target: "_blank"
          }, "Leon Strasky"),
          _createTextVNode(" if you catch me online in the chat! ")
        ], -1)
      ])),
      _: 1
    }, 8, ["class"]),
    _createVNode(_component_Card, {
      class: _normalizeClass(["p-m-2", [_ctx.options.nightMode ? 'night':'day' ]]),
      style: {"width":"30rem","margin-bottom":"2em"}
    }, {
      title: _withCtx(() => _cache[16] || (_cache[16] = [
        _createTextVNode(" How can I support? ")
      ])),
      content: _withCtx(() => _cache[17] || (_cache[17] = [
        _createElementVNode("p", null, " Two ways in which you can support FFA! First is to spread the word about this tool. There is a certain network-effect involved and the more people use it, the better it gets for everyone. Since it doesn't just allow you to more easily find others, it also allows others to more easily find you. A win-win for everyone! I'm always looking for feedback no matter how blunt or small it is. Something you dislike, something you think would be better in a different way, some new feature that you feel would support in finding partners. Please let me know! ", -1)
      ])),
      _: 1
    }, 8, ["class"])
  ]))
}