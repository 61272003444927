import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "p-mt-2 p-mr-2 p-ml-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Message = _resolveComponent("Message")!
  const _component_Toast = _resolveComponent("Toast")!
  const _component_NavBar = _resolveComponent("NavBar")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["background-height", [_ctx.options.nightMode ? 'background-color-night':'background-color']])
  }, [
    (_ctx.maxConnError)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_Message, {
            severity: "error",
            closable: true
          }, {
            default: _withCtx(() => _cache[0] || (_cache[0] = [
              _createTextVNode("Too many connections to FFA. ")
            ])),
            _: 1
          })
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_Toast, { position: "bottom-right" }),
    _createVNode(_component_NavBar),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_router_view, null, {
        default: _withCtx(({ Component }) => [
          (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
        ]),
        _: 1
      })
    ])
  ], 2))
}