import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, resolveDirective as _resolveDirective, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["onClick"]
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Column = _resolveComponent("Column")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Dialog = _resolveComponent("Dialog")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createBlock(_component_Dialog, {
    header: "Saved Ad Searches",
    visible: _ctx.visibilityRef,
    "onUpdate:visible": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.visibilityRef) = $event)),
    modal: true,
    contentStyle: { overflow: 'auto' }
  }, {
    footer: _withCtx(() => [
      _createVNode(_component_Button, {
        label: "Cancel",
        icon: "pi pi-times",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.cancel())),
        class: "p-button-text"
      })
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", null, [
        (_ctx.savedAdSearches.length > 0)
          ? (_openBlock(), _createBlock(_component_DataTable, {
              key: 0,
              value: _ctx.savedAdSearches,
              responsiveLayout: "scroll"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_Column, {
                  field: "adSearchName",
                  header: "Name"
                }),
                _createVNode(_component_Column, {
                  field: "saveTime",
                  header: "Saved At"
                }, {
                  body: _withCtx((slotProps) => [
                    _createTextVNode(_toDisplayString(slotProps.data.saveTime.toLocaleString()), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_Column, {
                  field: "id",
                  header: "Actions"
                }, {
                  body: _withCtx((slotProps) => [
                    _withDirectives(_createElementVNode("i", {
                      class: "pi pi-file load",
                      onClick: ($event: any) => (_ctx.loadAd(slotProps.data.id))
                    }, null, 8, _hoisted_1), [
                      [_directive_tooltip, 'Load AdSearch']
                    ]),
                    _withDirectives(_createElementVNode("i", {
                      class: "p-pl-2 pi pi-times delete",
                      onClick: ($event: any) => (_ctx.deleteAd(slotProps.data.id))
                    }, null, 8, _hoisted_2), [
                      [_directive_tooltip, 'Delete AdSearch']
                    ])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["value"]))
          : (_openBlock(), _createElementBlock("div", _hoisted_3, "You currently have no saved AdSearches."))
      ])
    ]),
    _: 1
  }, 8, ["visible"]))
}