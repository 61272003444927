import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "p-pt-1" }
const _hoisted_2 = ["for"]
const _hoisted_3 = ["for"]
const _hoisted_4 = ["for"]
const _hoisted_5 = ["for"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MultiSelect = _resolveComponent("MultiSelect")!
  const _component_Checkbox = _resolveComponent("Checkbox")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", null, [
      _createVNode(_component_MultiSelect, {
        loading: _ctx.kinks.length === 0,
        modelValue: _ctx.kinks.length === 0 ? [] : _ctx.kinkFilter.kinkIds ? _ctx.kinkFilter.kinkIds.map(value => value.toString()) : [],
        onChange: _ctx.kinkListChange,
        options: _ctx.kinks,
        optionLabel: "name",
        optionValue: "id",
        filter: true,
        showToggleAll: false,
        placeholder: "Select kinks to filter...",
        listStyle: "max-height:150px",
        style: {"width":"13rem"}
      }, null, 8, ["loading", "modelValue", "onChange", "options"]),
      _createElementVNode("i", {
        class: "pi pi-times card-icon delete-icon",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.deleteKinkFilter && _ctx.deleteKinkFilter(...args)))
      })
    ]),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_Checkbox, {
        onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.checkboxChange('fave'))),
        id: 'fave'+_ctx.kinkFilter.id,
        modelValue: _ctx.kinkFilter.fave,
        binary: true
      }, null, 8, ["id", "modelValue"]),
      _createElementVNode("label", {
        class: "kinkCheckboxLabel",
        for: 'fave'+_ctx.kinkFilter.id
      }, "Fave", 8, _hoisted_2),
      _createVNode(_component_Checkbox, {
        class: "kinkCheckbox",
        onChange: _cache[2] || (_cache[2] = ($event: any) => (_ctx.checkboxChange('yes'))),
        id: 'yes'+_ctx.kinkFilter.id,
        modelValue: _ctx.kinkFilter.yes,
        binary: true
      }, null, 8, ["id", "modelValue"]),
      _createElementVNode("label", {
        class: "kinkCheckboxLabel",
        for: 'yes'+_ctx.kinkFilter.id
      }, "Yes", 8, _hoisted_3),
      _createVNode(_component_Checkbox, {
        class: "kinkCheckbox",
        onChange: _cache[3] || (_cache[3] = ($event: any) => (_ctx.checkboxChange('maybe'))),
        id: 'maybe'+_ctx.kinkFilter.id,
        modelValue: _ctx.kinkFilter.maybe,
        binary: true
      }, null, 8, ["id", "modelValue"]),
      _createElementVNode("label", {
        class: "kinkCheckboxLabel",
        for: 'maybe'+_ctx.kinkFilter.id
      }, "Mb", 8, _hoisted_4),
      _createVNode(_component_Checkbox, {
        class: "kinkCheckbox",
        onChange: _cache[4] || (_cache[4] = ($event: any) => (_ctx.checkboxChange('no'))),
        id: 'no'+_ctx.kinkFilter.id,
        modelValue: _ctx.kinkFilter.no,
        binary: true
      }, null, 8, ["id", "modelValue"]),
      _createElementVNode("label", {
        class: "kinkCheckboxLabel",
        for: 'no'+_ctx.kinkFilter.id
      }, "No", 8, _hoisted_5)
    ])
  ]))
}