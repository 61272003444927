<template>
  <div class="p-d-flex p-ai-center p-flex-column full-size">
    <Card style="margin-bottom: 2em"  :class="[options.nightMode ? 'night':'day' ]">
      <template #title>
        <div class="p-d-flex p-flex-wrap p-jc-center">Options</div>
      </template>
      <template #content>
        <div class="p-d-flex p-flex-column p-ai-center">
          <ToggleButton
              v-model="hideavatars"
              onLabel="Avatars displayed"
              offLabel="Avatars hidden"
              onIcon="pi pi-eye"
              offIcon="pi pi-eye-slash"
              style="width: 15em"
          />
          <div class="p-mt-3">
            <ToggleButton
                v-model="hideeicons"
                onLabel="Eicons displayed"
                offLabel="Eicons hidden"
                onIcon="pi pi-eye"
                offIcon="pi pi-eye-slash"
                style="width: 15em"
            />
          </div>
          <div class="p-mt-3">
            <ToggleButton
                v-model="timeOnly"
                onLabel="Time only"
                offLabel="Time + Date"
                onIcon="pi pi-clock"
                offIcon="pi pi-calendar"
                style="width: 15em"
            />
          </div>
          <div class="p-mt-3">
            <ToggleButton
                v-model="filterDuplicates"
                onLabel="Filter duplicates"
                offLabel="Show duplicates"
                onIcon="pi pi-filter"
                offIcon="pi pi-filter-slash"
                style="width: 15em"
            />
          </div>
          <div class="p-mt-3">
            <ToggleButton
                v-model="nightMode"
                onLabel="Night Mode On"
                offLabel="Night Mode Off"
                onIcon="pi pi-moon"
                offIcon="pi pi-sun"
                style="width: 15em"
            />
          </div>

          <h3 class="p-pt-3 p-pb-2">Ignore List ({{ fullIgnores.size }})</h3>
          <div class="p-d-flex p-flex-wrap p-jc-center">
            <div :key="ignore" v-for="ignore in limitedIgnores" class="p-m-1">
              <Chip
                  :label="ignore"
                  removable
                  v-on:remove="removeIgnore(ignore)"
              />
            </div>
            <div v-if="limitedIgnores.length == 0 || limitedIgnores.length == undefined">
              Your ignore list is empty. You can ignore/hide all ads and status
              messages from a character by clicking the
              <i class="pi pi-eye-slash"></i> icon.
            </div>
          </div>
          <div v-if="limitIgnores && fullIgnores.size > 35">
            <span>Not all your ignores are being displayed.</span>
          </div>
          <Button
              style="margin-top: 10px"
              label="Show all"
              @click="showAllIgnores()"
              v-if="limitIgnores && fullIgnores.size > 35"
          />
          <div v-if="limitedIgnores.length != 0" class="p-pt-2">
            <Button
                icon="pi pi-trash"
                label="Remove all Ignores"
                class="p-button-danger"
                @click="purgeIgnore()"
            />
          </div>
        </div>
      </template>
    </Card>
  </div>
</template>

<script lang="ts">
import {computed, defineComponent, ref} from "vue";
import {useStore} from "../store/store";

export default defineComponent({
  name: "OptionsView",
  components: {},
  setup() {
    const store = useStore();
    const purgeIgnore = store.purgeIgnore;
    const limitIgnores = ref<boolean>(true);
    const hideavatars = computed({
      get: () => store.getOptions.value.hideAvatars,
      set: (value: boolean) => store.setHideAvatars(value),
    });
    const hideeicons = computed({
      get: () => store.getOptions.value.hideEicons,
      set: (value: boolean) => store.setHideEicons(value),
    });
    const timeOnly = computed({
      get: () => store.getOptions.value.timeOnly,
      set: (value: boolean) => store.setTimeOnly(value),
    });
    const filterDuplicates = computed({
      get: () => store.getOptions.value.filterDuplicates,
      set: (value: boolean) => store.setFilterDuplicates(value),
    });
    const nightMode = computed({
      get: () => store.getOptions.value.nightMode,
      set: (value: boolean) => store.setNightMode(value),
    });
    const showAllIgnores = function (){
      limitIgnores.value = false
    }
    const limitedIgnores = computed(
        () => {
          if (limitIgnores.value) {
            return Array.from(store.getOptions.value.ignoreSet).slice(0, 35)
          } else {
            return Array.from(store.getOptions.value.ignoreSet)
          }
        }
    )
    const options = store.getOptions
    return {
      hideeicons,
      hideavatars,
      limitIgnores,
      filterDuplicates,
      timeOnly,
      limitedIgnores,
      nightMode,
      options,
      showAllIgnores,
      fullIgnores: computed(() => store.getOptions.value.ignoreSet),
      removeIgnore: store.removeIgnore,
      purgeIgnore,
    };
  },
});
</script>

<style lang="scss" scoped>
@import '@/scss/variables';
</style>
