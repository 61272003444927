import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, resolveDirective as _resolveDirective, withDirectives as _withDirectives, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "left"
}
const _hoisted_2 = ["href"]
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "right p-p-2" }
const _hoisted_5 = { class: "font-bowlby" }
const _hoisted_6 = ["href"]
const _hoisted_7 = ["onClick"]
const _hoisted_8 = { class: "p-pb-2" }
const _hoisted_9 = { key: 0 }
const _hoisted_10 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["p-mb-1 relative break-words", [
      !_ctx.hideAvatars ? 'display-grid-no-ava' : 'display-grid',
      _ctx.genderClass,
      _ctx.nightmodeActive ? 'nightmode-brightness':''
    ]])
  }, [
    (_ctx.hideAvatars)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("a", {
            class: "avatar",
            href: _ctx.profileUrl,
            target: "_blank"
          }, [
            _createElementVNode("img", {
              src: _ctx.avatarUrl,
              alt: "avatar",
              class: "avatar"
            }, null, 8, _hoisted_3)
          ], 8, _hoisted_2)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("span", _hoisted_5, [
        (!_ctx.hideAvatars)
          ? (_openBlock(), _createElementBlock("a", {
              key: 0,
              href: _ctx.profileUrl,
              target: "_blank"
            }, _toDisplayString(_ctx.ad.characterName), 9, _hoisted_6))
          : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
              _createTextVNode(_toDisplayString(_ctx.ad.characterName), 1)
            ], 64)),
        (['adlist', 'adsearch'].includes(_ctx.mode))
          ? _withDirectives((_openBlock(), _createElementBlock("i", {
              key: 2,
              class: "p-pl-2 pi pi-eye-slash ignorecon",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.pushIgnore()))
            }, null, 512)), [
              [
                _directive_tooltip,
                'Hide Ads/Status of this character',
                void 0,
                { top: true }
              ]
            ])
          : _createCommentVNode("", true),
        (['adlist', 'adsearch'].includes(_ctx.mode))
          ? _withDirectives((_openBlock(), _createElementBlock("i", {
              key: 3,
              class: "p-pl-2 pi pi-heart favecon",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.pushFavorite()))
            }, null, 512)), [
              [
                _directive_tooltip,
                'Save Ad to Favorites',
                void 0,
                { top: true }
              ]
            ])
          : _createCommentVNode("", true),
        (['favorites'].includes(_ctx.mode))
          ? _withDirectives((_openBlock(), _createElementBlock("i", {
              key: 4,
              class: "p-pl-2 pi pi-times ignorecon",
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.dropFavorite(_ctx.ad)))
            }, null, 512)), [
              [
                _directive_tooltip,
                'Remove Ad from Favorites',
                void 0,
                { top: true }
              ]
            ])
          : _createCommentVNode("", true),
        _createVNode(_component_router_link, {
          to: '/charsummary/'+_ctx.ad.characterName,
          custom: ""
        }, {
          default: _withCtx(({navigate}) => [
            _withDirectives(_createElementVNode("i", {
              class: "p-pl-2 pi pi-list bluecon",
              onClick: navigate
            }, null, 8, _hoisted_7), [
              [
                _directive_tooltip,
                'Go to Character Summary',
                void 0,
                { top: true }
              ]
            ])
          ]),
          _: 1
        }, 8, ["to"])
      ]),
      _createElementVNode("div", _hoisted_8, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.attributes, (att, index) => {
          return (_openBlock(), _createElementBlock("span", {
            key: att.attributeValue
          }, [
            (att != _ctx.noInfo)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  _createTextVNode(_toDisplayString(att), 1),
                  (index != _ctx.attributes.length - 1)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_9, " | "))
                    : _createCommentVNode("", true)
                ], 64))
              : _createCommentVNode("", true)
          ]))
        }), 128))
      ]),
      _createElementVNode("div", {
        ref: "adroot",
        class: _normalizeClass([_ctx.isExpanded ? 'flist-ad-expanded' : 'flist-ad'])
      }, [
        _createElementVNode("b", null, [
          _createTextVNode(_toDisplayString(_ctx.time) + " ", 1),
          _createElementVNode("div", {
            innerHTML: _ctx.ad.parsedMessage,
            class: _normalizeClass([_ctx.hideEicons ? 'show-eicon' : 'hide-eicon'])
          }, null, 10, _hoisted_10)
        ])
      ], 2)
    ]),
    (!_ctx.isExpanded && _ctx.overflows)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: "conditional-dropdown",
          onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.isExpanded = true))
        }, _cache[4] || (_cache[4] = [
          _createElementVNode("i", { class: "pi pi-angle-down" }, null, -1)
        ])))
      : _createCommentVNode("", true)
  ], 2))
}