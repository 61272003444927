import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withKeys as _withKeys } from "vue"

const _hoisted_1 = { class: "p-d-flex p-jc-center" }
const _hoisted_2 = { class: "p-d-flex p-jc-center p-flex-wrap" }
const _hoisted_3 = { class: "p-p-2" }
const _hoisted_4 = { class: "p-p-2" }
const _hoisted_5 = { class: "p-p-2" }
const _hoisted_6 = { class: "p-p-2" }
const _hoisted_7 = { class: "p-p-2" }
const _hoisted_8 = { class: "p-p-2" }
const _hoisted_9 = { class: "p-p-2" }
const _hoisted_10 = { class: "p-d-flex" }
const _hoisted_11 = { class: "p-pl-4" }
const _hoisted_12 = {
  id: "kinks",
  class: "p-d-flex p-flex-column p-ai-center"
}
const _hoisted_13 = { class: "p-d-flex p-flex-wrap p-jc-center p-ai-center" }
const _hoisted_14 = {
  id: "keywords",
  class: "p-d-flex p-flex-column p-ai-center"
}
const _hoisted_15 = { class: "p-d-flex p-jc-center p-flex-wrap" }
const _hoisted_16 = { class: "p-pt-3" }
const _hoisted_17 = {
  key: 0,
  class: "keywords p-pt-2"
}
const _hoisted_18 = {
  key: 0,
  class: "p-pt-2"
}
const _hoisted_19 = { class: "p-d-flex p-jc-center" }
const _hoisted_20 = {
  key: 1,
  class: "keywords p-pt-2"
}
const _hoisted_21 = {
  key: 1,
  class: "p-pt-2"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FilterMultiselect = _resolveComponent("FilterMultiselect")!
  const _component_InputNumber = _resolveComponent("InputNumber")!
  const _component_KinkFilterSelect = _resolveComponent("KinkFilterSelect")!
  const _component_Button = _resolveComponent("Button")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_ToggleButton = _resolveComponent("ToggleButton")!
  const _component_AdSearchSaveDialog = _resolveComponent("AdSearchSaveDialog")!
  const _component_AdSearchLoadDialog = _resolveComponent("AdSearchLoadDialog")!
  const _component_ProgressBar = _resolveComponent("ProgressBar")!
  const _component_Ad = _resolveComponent("Ad")!
  const _component_Paginator = _resolveComponent("Paginator")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h2", {
        class: _normalizeClass(["p-pb-3 font-bowlby", [_ctx.options.nightMode ? 'night-text':'day-text' ]])
      }, "Ad Search", 2)
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_FilterMultiselect, {
          "night-mode": _ctx.options.nightMode,
          title: 'Gender',
          availableOptions: _ctx.availableGenders,
          selectedItems: _ctx.selectedGenders,
          placeholder: 'Select genders',
          selecteditems: _ctx.selectedGenders,
          "onUpdate:selecteditems": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedGenders) = $event))
        }, null, 8, ["night-mode", "availableOptions", "selectedItems", "selecteditems"])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_FilterMultiselect, {
          "night-mode": _ctx.options.nightMode,
          title: 'Bodytype',
          availableOptions: _ctx.availableBodytypes,
          selectedItems: _ctx.selectedBodytypes,
          placeholder: 'Select bodytypes',
          selecteditems: _ctx.selectedBodytypes,
          "onUpdate:selecteditems": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedBodytypes) = $event))
        }, null, 8, ["night-mode", "availableOptions", "selectedItems", "selecteditems"])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_FilterMultiselect, {
          "night-mode": _ctx.options.nightMode,
          title: 'Orientation',
          availableOptions: _ctx.availableOrientations,
          selectedItems: _ctx.selectedOrientations,
          placeholder: 'Select orientations',
          selecteditems: _ctx.selectedOrientations,
          "onUpdate:selecteditems": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.selectedOrientations) = $event))
        }, null, 8, ["night-mode", "availableOptions", "selectedItems", "selecteditems"])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_FilterMultiselect, {
          "night-mode": _ctx.options.nightMode,
          title: 'Furry Preference',
          availableOptions: _ctx.availableFurryPref,
          selectedItems: _ctx.selectedHumanFurryPref,
          placeholder: 'Select furry preference',
          selecteditems: _ctx.selectedHumanFurryPref,
          "onUpdate:selecteditems": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.selectedHumanFurryPref) = $event))
        }, null, 8, ["night-mode", "availableOptions", "selectedItems", "selecteditems"])
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createVNode(_component_FilterMultiselect, {
          "night-mode": _ctx.options.nightMode,
          title: 'Dom-Sub',
          availableOptions: _ctx.availableSubDom,
          selectedItems: _ctx.selectedDomSub,
          placeholder: 'Select dom/sub',
          selecteditems: _ctx.selectedDomSub,
          "onUpdate:selecteditems": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.selectedDomSub) = $event))
        }, null, 8, ["night-mode", "availableOptions", "selectedItems", "selecteditems"])
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createVNode(_component_FilterMultiselect, {
          "night-mode": _ctx.options.nightMode,
          title: 'Language Preference',
          availableOptions: _ctx.availableLanguagePref,
          selectedItems: _ctx.selectedLanguagePref,
          placeholder: 'Select language',
          selecteditems: _ctx.selectedLanguagePref,
          "onUpdate:selecteditems": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.selectedLanguagePref) = $event))
        }, null, 8, ["night-mode", "availableOptions", "selectedItems", "selecteditems"])
      ]),
      _createElementVNode("div", _hoisted_9, [
        _createVNode(_component_FilterMultiselect, {
          "night-mode": _ctx.options.nightMode,
          title: 'Position',
          availableOptions: _ctx.availablePosition,
          selectedItems: _ctx.selectedPosition,
          placeholder: 'Select position',
          selecteditems: _ctx.selectedPosition,
          "onUpdate:selecteditems": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.selectedPosition) = $event))
        }, null, 8, ["night-mode", "availableOptions", "selectedItems", "selecteditems"])
      ]),
      _createElementVNode("div", {
        class: _normalizeClass(["p-p-2", [_ctx.options.nightMode ? 'night-text':'day-text' ]])
      }, [
        _createElementVNode("div", _hoisted_10, [
          _createElementVNode("div", null, [
            _cache[22] || (_cache[22] = _createElementVNode("div", { class: "p-py-2 p-d-flex p-jc-between p-ai-center" }, [
              _createElementVNode("h3", null, "Min Age")
            ], -1)),
            _createVNode(_component_InputNumber, {
              min: 0,
              max: 99999999,
              modelValue: _ctx.minAge,
              "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.minAge) = $event)),
              inputClass: 'number-input'
            }, null, 8, ["modelValue"])
          ]),
          _createElementVNode("div", _hoisted_11, [
            _cache[23] || (_cache[23] = _createElementVNode("div", { class: "p-py-2 p-d-flex p-jc-between p-ai-center" }, [
              _createElementVNode("h3", null, "Max Age")
            ], -1)),
            _createVNode(_component_InputNumber, {
              min: 0,
              max: 99999999,
              modelValue: _ctx.maxAge,
              "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.maxAge) = $event)),
              inputClass: 'number-input'
            }, null, 8, ["modelValue"])
          ])
        ])
      ], 2)
    ]),
    _createElementVNode("div", _hoisted_12, [
      _createElementVNode("h3", {
        class: _normalizeClass(["p-pt-2 p-b-1", [_ctx.options.nightMode ? 'night-text':'day-text' ]])
      }, "Kinks", 2),
      _createElementVNode("div", _hoisted_13, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.selectedKinks, (kinkfilter) => {
          return (_openBlock(), _createBlock(_component_KinkFilterSelect, {
            class: "p-px-3 p-mt-2",
            "kink-filter": kinkfilter,
            kinks: _ctx.getKinks(kinkfilter.id),
            onSectionToggle: _ctx.kinkFilterToggle,
            onKinkListToggle: _ctx.kinkListToggle,
            onDeleteKinkFilter: _ctx.deleteKinkFilter,
            key: kinkfilter.id
          }, null, 8, ["kink-filter", "kinks", "onSectionToggle", "onKinkListToggle", "onDeleteKinkFilter"]))
        }), 128)),
        (_ctx.selectedKinks.length < 10)
          ? (_openBlock(), _createBlock(_component_Button, {
              key: 0,
              icon: "pi pi-plus",
              class: "p-button-rounded p-button-raised p-mt-2",
              onClick: _ctx.addKinkFilter
            }, null, 8, ["onClick"]))
          : _createCommentVNode("", true)
      ])
    ]),
    _createElementVNode("div", _hoisted_14, [
      _createElementVNode("h3", {
        class: _normalizeClass(["p-pt-2 p-b-1", [_ctx.options.nightMode ? 'night-text':'day-text' ]])
      }, "Keywords", 2),
      _createElementVNode("div", _hoisted_15, [
        _createVNode(_component_InputText, {
          onKeyup: _cache[9] || (_cache[9] = _withKeys(($event: any) => (_ctx.searchAds(0)), ["enter"])),
          class: "keywords p-mt-1",
          type: "text",
          modelValue: _ctx.keywords,
          "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.keywords) = $event)),
          placeholder: "Cum,Anal,Incest..."
        }, null, 8, ["modelValue"]),
        _createVNode(_component_ToggleButton, {
          class: "p-ml-2 p-mt-1",
          modelValue: _ctx.andKeywords,
          "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.andKeywords) = $event)),
          onLabel: "All keywords mode",
          offLabel: "Any keywords mode",
          style: {"width":"12em"}
        }, null, 8, ["modelValue"])
      ]),
      _createElementVNode("div", _hoisted_16, [
        _createVNode(_component_Button, {
          disabled: _ctx.isSearchRunning,
          label: "Search",
          icon: "pi pi-search",
          iconPos: "left",
          onClick: _cache[12] || (_cache[12] = ($event: any) => (_ctx.searchAds(0)))
        }, null, 8, ["disabled"]),
        _createVNode(_component_Button, {
          class: "p-ml-2 p-button-secondary",
          disabled: _ctx.isSearchRunning,
          label: "Reset",
          icon: "pi pi-backward",
          iconPos: "left",
          onClick: _cache[13] || (_cache[13] = ($event: any) => (_ctx.resetSearch()))
        }, null, 8, ["disabled"]),
        _createVNode(_component_Button, {
          class: "p-ml-2 p-button-primary",
          disabled: _ctx.isSearchRunning,
          label: "Save",
          icon: "pi pi-save",
          iconPos: "left",
          onClick: _cache[14] || (_cache[14] = ($event: any) => (_ctx.adSearchSaveDialogVisible = true))
        }, null, 8, ["disabled"]),
        (_ctx.adSearchSaveDialogVisible)
          ? (_openBlock(), _createBlock(_component_AdSearchSaveDialog, {
              key: 0,
              visibility: _ctx.adSearchSaveDialogVisible,
              "onUpdate:visibility": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.adSearchSaveDialogVisible) = $event)),
              onSave: _cache[16] || (_cache[16] = ($event: any) => (_ctx.saveAdSearch($event)))
            }, null, 8, ["visibility"]))
          : _createCommentVNode("", true),
        _createVNode(_component_Button, {
          class: "p-ml-2 p-button-primary",
          disabled: _ctx.isSearchRunning,
          label: "Load",
          icon: "pi pi-file",
          iconPos: "left",
          onClick: _cache[17] || (_cache[17] = ($event: any) => (_ctx.adSearchLoadDialogVisible = true))
        }, null, 8, ["disabled"]),
        (_ctx.adSearchLoadDialogVisible)
          ? (_openBlock(), _createBlock(_component_AdSearchLoadDialog, {
              key: 1,
              visibility: _ctx.adSearchLoadDialogVisible,
              "onUpdate:visibility": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.adSearchLoadDialogVisible) = $event)),
              onLoad: _cache[19] || (_cache[19] = ($event: any) => (_ctx.loadAdSearch($event))),
              onDelete: _cache[20] || (_cache[20] = ($event: any) => (_ctx.deleteAdSearch($event))),
              "saved-ad-searches": _ctx.adSearches
            }, null, 8, ["visibility", "saved-ad-searches"]))
          : _createCommentVNode("", true)
      ]),
      (_ctx.isSearchRunning)
        ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
            _createVNode(_component_ProgressBar, {
              mode: "indeterminate",
              style: {"height":"0.5em"}
            })
          ]))
        : _createCommentVNode("", true)
    ]),
    (_ctx.adSearchResponse)
      ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.adSearchResponse.ads, (ad) => {
            return (_openBlock(), _createBlock(_component_Ad, {
              class: "p-shadow-2",
              key: ad.key,
              ad: ad,
              "is-favorites": false,
              mode: "adsearch"
            }, null, 8, ["ad"]))
          }), 128))
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_19, [
      (_ctx.adSearchResponse != null && !_ctx.lastAdSearchHasResults)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass([_ctx.options.nightMode ? 'night-text':'day-text'])
          }, " Sorry I could not find anything :( ", 2))
        : _createCommentVNode("", true),
      (_ctx.isSearchRunning && _ctx.lastAdSearchHasResults)
        ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
            _createVNode(_component_ProgressBar, {
              mode: "indeterminate",
              style: {"height":"0.5em"}
            })
          ]))
        : _createCommentVNode("", true)
    ]),
    (_ctx.lastAdSearchHasResults)
      ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
          (_openBlock(), _createBlock(_component_Paginator, {
            class: _normalizeClass([_ctx.options.nightMode ? 'night-background':'' ]),
            key: _ctx.paginationHack,
            rows: 50,
            totalRecords: _ctx.adSearchResponse.numElements,
            onPage: _cache[21] || (_cache[21] = ($event: any) => (_ctx.searchAds($event.page)))
          }, null, 8, ["class", "totalRecords"]))
        ]))
      : _createCommentVNode("", true)
  ]))
}