<template>
  <div>
    <FilterList></FilterList>
    <div class="p-d-flex p-jc-center p-ai-center p-pb-2 p-pt-2">
      <Button type="button" icon="pi pi-cog" :label="'AdList Options'" @click="toggle"/>
      <OverlayPanel ref="op" appendTo="body" :showCloseIcon="false" id="overlay_panel" style="width: 350px"
                    :breakpoints="{'960px': '75vw'}">
        <div class="p-d-flex p-ai-center p-flex-column">
          <ToggleButton
              class="pause-button  p-mr-3"
              v-model="pauseAdList"
              onLabel="Resume AdList"
              offLabel="Pause AdList"
              onIcon="pi pi-play"
              offIcon="pi pi-pause"
              style="width: 15em"
          />
          <hr>
          <div class="p-d-flex p-ai-center">
            <Checkbox id="freshads" v-model="toggleFresh" :binary="true"/>
            <label class="p-ml-2" for="freshads">Show Fresh Ads Only</label>
            <i class="pi pi-info-circle p-ml-2 pointer" v-tooltip.right="'Only show ads that premiered (were posted for the first time) ' +
             'at most 7 days ago. See FAQ for more information.'" style="font-size: 1rem"></i>
          </div>
        </div>
      </OverlayPanel>
    </div>
    <div v-if="adListIsLoading" class="p-d-flex p-jc-center p-pt-2">
      <ProgressBar mode="indeterminate" style="height: 0.5em; width: 18rem"/>
    </div>
    <div v-if="!adListIsLoading && getAds.length === 0" class="p-d-flex p-jc-center p-pt-2" :class="[options.nightMode ? 'night-text':'day-text' ]">
      Sorry there are no ads matching your filters :(
    </div>
    <div :class="{ 'pause-border': isPaused }">
      <Ad
          class="p-shadow-2"
          v-for="ad in getAds"
          v-bind:key="ad.key"
          v-bind:ad="ad"
          mode="adlist"
          :is-favorites="false"
      >
      </Ad>
    </div>
  </div>
</template>

<script lang="ts">
import {computed, defineComponent, ref} from "vue";
import {useStore} from "../store/store";
import Ad from "../components/Ad.vue";
import FilterList from "../components/FilterList.vue";

export default defineComponent({
  name: "AdView",
  components: {
    Ad,
    FilterList,
  },
  setup() {
    const op = ref();
    const toggle = (event: unknown) => {
      op.value.toggle(event);
    };
    const store = useStore();
    const isPaused = computed(() => store.getOptions.value.adListPaused);
    const pauseAdList = computed({
      get: () => store.getOptions.value.adListPaused,
      set: (value: boolean) => store.setPauseAdlist(value),
    });
    const toggleFresh = computed({
      get: () => store.getOptions.value.freshAdsOnly,
      set: (value: boolean) => store.setFreshAdsOnly(value),
    });
    const options = store.getOptions
    return {
      options,
      op,
      adListIsLoading: store.isAdListEmpty,
      getAds: store.getFilteredAds,
      sidebarvisible: false,
      pauseAdList,
      isPaused,
      toggle,
      toggleFresh,
    };
  },
});
</script>
<style lang="scss" scoped>
@import '@/scss/variables';
.pause-border {
  background-color: rgb(255, 0, 0);
}

.pointer{
  cursor:pointer;
}

hr {
  margin: 0;
  align-self: stretch;
  margin-top: 10px;
  margin-bottom: 10px;
}
</style>

