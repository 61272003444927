import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, renderList as _renderList, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["href"]
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "font-bowlby" }
const _hoisted_4 = ["href"]
const _hoisted_5 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([[_ctx.genderClass,_ctx.nightmodeActive ? 'nightmode-brightness':''], "p-p-3 p-m-1 p-d-flex p-ai-center p-flex-column border-round"])
  }, [
    (_ctx.hideAvatars)
      ? (_openBlock(), _createElementBlock("a", {
          key: 0,
          class: "avatar",
          href: _ctx.profileUrl,
          target: "_blank"
        }, [
          _createElementVNode("img", {
            src: _ctx.avatarUrl,
            alt: "avatar",
            class: "avatar"
          }, null, 8, _hoisted_2)
        ], 8, _hoisted_1))
      : _createCommentVNode("", true),
    _createElementVNode("span", _hoisted_3, [
      (!_ctx.hideAvatars)
        ? (_openBlock(), _createElementBlock("a", {
            key: 0,
            href: _ctx.profileUrl,
            target: "_blank"
          }, _toDisplayString(_ctx.characterName), 9, _hoisted_4))
        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            _createTextVNode(_toDisplayString(_ctx.characterName), 1)
          ], 64))
    ]),
    _createElementVNode("div", null, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.attributes, (att, index) => {
        return (_openBlock(), _createElementBlock("span", {
          key: att.attributeValue
        }, [
          (att != _ctx.noInfo)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                _createTextVNode(_toDisplayString(att), 1),
                (index != _ctx.attributes.length - 1)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_5, " | "))
                  : _createCommentVNode("", true)
              ], 64))
            : _createCommentVNode("", true)
        ]))
      }), 128))
    ])
  ], 2))
}