<template>
  <div :class="[options.nightMode ? 'night':'day' ]">
    <div class="filter-list p-p-3 p-shadow-2">
      <FilterCard
        :filter="filter"
        v-for="filter in getFilters"
        :key="filter.id"
      >
      </FilterCard>
      <Button
        icon="pi pi-plus"
        class="p-button-rounded  p-button-raised"
        @click="filterDialogVisibility = true"
      />
    </div>
    <template v-if="filterDialogVisibility">
      <FilterDialog
        v-model:visibility="filterDialogVisibility"
        :filterUuid="''"
      ></FilterDialog>
    </template>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import FilterDialog from "./FilterDialog.vue";
import FilterCard from "./FilterCard.vue";
import { useStore } from "../store/store";
export default defineComponent({
  name: "FilterList",
  components: {
    FilterDialog,
    FilterCard,
  },
  setup() {
    const store = useStore();
    const filterDialogVisibility = ref(false);
    const options = store.getOptions
    return {
      options,
      filterDialogVisibility,
      getFilters: store.getFilters,
    };
  },
});
</script>

<style lang="scss" scoped>
.filter-list {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-start;
  border-radius: 4px;
}

.background-color {
  background-color: white;
}
</style>