<template>
  <div :class="[genderClass,     nightmodeActive ? 'nightmode-brightness':'']" class="p-p-2 p-m-1 border-round overflow-hidden">
    <div class="p-d-flex p-mb-2 min-height">
      <a v-if="hideAvatars" class="avatar" :href="profileUrl" target="_blank"
      ><img :src="avatarUrl" alt="avatar" class="avatar"
      /></a>
      <div class="p-d-flex p-flex-column" :class="{ 'p-ml-2': hideAvatars }">
        <span class="font-bowlby">
          <template v-if="!hideAvatars">
            <a :href="profileUrl" target="_blank">{{ ad.characterName }}</a>
          </template>
          <template v-else>
            {{ ad.characterName }}
          </template>
          <i
              class="p-pl-2 pi pi-eye-slash ignorecon"
              @click="pushIgnore(ad.characterName)"
              v-tooltip.top="'Hide Ads/Status of this character'"
          ></i>
                  <router-link :to="'/charsummary/'+ad.characterName" custom v-slot="{navigate}">
                  <i
                      class="p-pl-2 pi pi-list bluecon"
                      @click="navigate"
                      v-tooltip.top="'Go to Character Summary'"
                  ></i>
        </router-link>
        </span>
        <div>
          <span v-for="(att, index) in attributes" :key="att.attributeValue">
            <template v-if="att != noInfo">
              {{ att }}<span v-if="index != attributes.length - 1"> | </span>
            </template>
          </span>
        </div>
      </div>
    </div>
    <div class="break-words">
      <b>
        <div v-html="ad.parsedMessage" :class="[hideEicons ? 'show-eicon' : 'hide-eicon']"></div>
      </b>
    </div>
  </div>
</template>

<script lang="ts">
import {computed, defineComponent, PropType} from "vue";
import {Status} from "../store/state";
import {useStore} from "../store/store";
import {normalizeGender} from "../ts/genderHelper";
import {getAvatarUrl, getProfileUrl} from "../ts/urlHelper";
import {useToast} from "primevue/usetoast";

export default defineComponent({
  name: "ffa-status",
  props: {
    ad: {
      type: Object as PropType<Status>,
      required: true,
    },
  },
  setup(props) {
    const store = useStore();
    const noInfo = "No Info";
    const toast = useToast();
    return {
      pushIgnore: (charname: string) => {
        const detailMsg = charname + " was added to Ignores. See Options.";
        toast.add({
          severity: "info",
          summary: "Ignore added",
          detail: detailMsg,
          life: 3000,
        });
        store.pushIgnore(charname);
      },
      hideAvatars: computed(() => store.getOptions.value.hideAvatars),
      hideEicons: computed(() => store.getOptions.value.hideEicons),
      nightmodeActive: computed(() => store.getOptions.value.nightMode),
      genderClass: normalizeGender(props.ad.characterGender),
      avatarUrl: getAvatarUrl(props.ad.characterName),
      profileUrl: getProfileUrl(props.ad.characterName),
      noInfo,
      attributes: computed(() => {
        return [
          props.ad.characterOrientation,
          props.ad.characterDomsub,
          props.ad.characterFurryPreference,
        ].filter((att) => att != noInfo);
      }),
    };
  },
});
</script>

<style lang="scss" scoped>
.ignorecon {
  cursor: pointer;
  font-weight: bold;
}

.ignorecon:hover {
  cursor: pointer;
  color: rgb(207, 0, 0);
}

a:visited {
  color: blue;
}


.bluecon:hover {
  cursor: pointer;
  color: rgb(10, 128, 255);
}

a:visited {
  color: blue;
}


.overflow-hidden {
  overflow: hidden;
}

.min-height {
  min-height: 60px;
}

.border-round {
  border-radius: 4px;
}

.avatar {
  padding-top: 3px;
  height: 55px;
  width: 55px;
}

.right {
  display: flex;
  flex-direction: column;
}

.left {
  display: flex;
  justify-content: center;
  padding-top: 15px;
}
</style>