import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MultiSelect = _resolveComponent("MultiSelect")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", {
      class: _normalizeClass(["p-py-2 p-d-flex p-jc-between p-ai-center", [_ctx.nightMode ? 'night-text':'day-text' ]])
    }, [
      _createElementVNode("h3", null, _toDisplayString(_ctx.title), 1)
    ], 2),
    _createVNode(_component_MultiSelect, {
      loading: _ctx.availableOptions.length === 0,
      modelValue: _ctx.items,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.items) = $event)),
      options: _ctx.availableOptions,
      placeholder: _ctx.placeholder,
      style: {"width":"15rem"}
    }, null, 8, ["loading", "modelValue", "options", "placeholder"])
  ]))
}