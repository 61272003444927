import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withKeys as _withKeys, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "p-d-flex p-jc-center" }
const _hoisted_2 = {
  id: "characterName",
  class: "p-d-flex p-flex-column p-ai-center"
}
const _hoisted_3 = { class: "p-d-flex p-jc-center p-flex-wrap" }
const _hoisted_4 = { class: "p-mr-2" }
const _hoisted_5 = {
  key: 0,
  class: "p-pt-2"
}
const _hoisted_6 = {
  key: 0,
  class: "p-pt-2"
}
const _hoisted_7 = { class: "p-d-flex p-ai-center p-flex-column p-p-4" }
const _hoisted_8 = {
  key: 0,
  class: "p-d-flex p-ai-center p-flex-column"
}
const _hoisted_9 = { class: "result-title" }
const _hoisted_10 = ["innerHTML"]
const _hoisted_11 = { key: 1 }
const _hoisted_12 = {
  key: 1,
  class: "p-pt-2"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Button = _resolveComponent("Button")!
  const _component_ProgressBar = _resolveComponent("ProgressBar")!
  const _component_CharacterSummary = _resolveComponent("CharacterSummary")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", null, [
        _createElementVNode("h2", {
          class: _normalizeClass(["p-pb-3 p-text-center font-bowlby", [_ctx.options.nightMode ? 'night-text':'day-text' ]])
        }, "Character Summary", 2)
      ])
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_InputText, {
            onKeyup: _cache[0] || (_cache[0] = _withKeys(($event: any) => (_ctx.fetchCharacterSummary(_ctx.characterName)), ["enter"])),
            class: "characterName",
            type: "text",
            modelValue: _ctx.characterName,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.characterName) = $event)),
            placeholder: "Character name"
          }, null, 8, ["modelValue"])
        ]),
        _createVNode(_component_Button, {
          disabled: _ctx.isSearchRunning,
          label: "Search",
          icon: "pi pi-search",
          iconPos: "left",
          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.fetchCharacterSummary(_ctx.characterName)))
        }, null, 8, ["disabled"])
      ]),
      (_ctx.isSearchRunning)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createVNode(_component_ProgressBar, {
              mode: "indeterminate",
              style: {"height":"0.5em","width":"18rem"}
            })
          ]))
        : _createCommentVNode("", true)
    ]),
    (_ctx.characterSummary && _ctx.characterSummary.success && !_ctx.isSearchRunning)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _createVNode(_component_CharacterSummary, {
              characterName: _ctx.characterSummary.characterName,
              characterGender: _ctx.characterSummary.characterGender,
              attributes: _ctx.attributes,
              lookings: _ctx.characterSummary.recentLookings
            }, null, 8, ["characterName", "characterGender", "attributes", "lookings"]),
            _createElementVNode("span", {
              class: _normalizeClass(["font-bowlby", [_ctx.options.nightMode ? 'night-text':'day-text' ]])
            }, "Recent status / ad activity", 2),
            (_ctx.combinedResult.length != 0)
              ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.combinedResult, (result) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: result.key,
                      class: _normalizeClass([[_ctx.genderClass,_ctx.nightmodeActive ? 'nightmode-brightness':''], "p-text-bold border-round p-shadow-2 p-m-1 p-p-2 width100 break-words"])
                    }, [
                      _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.calculateTime(result.date)) + " (" + _toDisplayString(result.type) + ")", 1),
                      _createElementVNode("div", {
                        innerHTML: result.text,
                        class: _normalizeClass([_ctx.hideEicons ? 'show-eicon' : 'hide-eicon'])
                      }, null, 10, _hoisted_10)
                    ], 2))
                  }), 128))
                ]))
              : (_openBlock(), _createElementBlock("div", _hoisted_11, "None"))
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.characterSummary && !_ctx.characterSummary.success)
      ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
          _createElementVNode("div", {
            class: _normalizeClass(["p-d-flex p-ai-center p-flex-column p-p-4", [_ctx.options.nightMode ? 'night-text':'day-text']])
          }, " Sorry I could not find anything :( ", 2)
        ]))
      : _createCommentVNode("", true)
  ]))
}