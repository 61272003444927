<template>
  <div>
    <div class="navbar p-d-flex p-jc-between">
      <router-link to="/" custom v-slot="{navigate}">
        <div @click='navigate' class="font-bowlby font-big logo p-d-flex p-ai-center p-pl-3 p-py-2">
          FFA 2.0
        </div>
      </router-link>
      <div v-if="screenType == 'screen'" class="p-d-flex p-px-3">
        <router-link to="/" custom v-slot="{navigate, isActive }">
          <NavButton
              @click="navigate"
              :isActive="isActive"
              :displayText="'AdList'"
          ></NavButton>
        </router-link>
        <router-link to="/statuslist" custom v-slot="{navigate, isActive }">
          <NavButton
              @click="navigate"
              :isActive="isActive"
              :displayText="'StatusList'"
          ></NavButton>
        </router-link>
        <router-link to="/adsearch" custom v-slot="{navigate, isActive }">
          <NavButton
              @click="navigate"
              :isActive="isActive"
              :displayText="'AdSearch'"
          ></NavButton>
        </router-link>
        <router-link :to="{name:'charsummary'}" custom v-slot="{navigate, isActive}">
          <NavButton
              @click="navigate"
              :isActive="isActive"
              :displayText="'CharSummary'"
          ></NavButton>
        </router-link>
        <router-link to="/favorites" custom v-slot="{navigate, isActive }">
          <NavButton
              @click="navigate"
              :isActive="isActive"
              :displayText="'Favorites'"
          ></NavButton>
        </router-link>
        <router-link to="/options" custom v-slot="{navigate, isActive }">
          <NavButton
              @click="navigate"
              :isActive="isActive"
              :displayText="'Options'"
          ></NavButton>
        </router-link>
        <router-link to="/faq" custom v-slot="{navigate, isActive }">
          <NavButton
              @click="navigate"
              :isActive="isActive"
              :displayText="'FAQ'"
          ></NavButton>
        </router-link>
      </div>
      <div v-else class="burger p-d-flex p-ai-center p-pr-4">
        <i
            class="pi pi-bars"
            style="font-size: 2rem;cursor: pointer;"
            @click="sidebarActive = !sidebarActive"
        ></i>
      </div>
    </div>
    <Sidebar class="sidebar" v-model:visible="sidebarVisible" >
      <div class="sidebar">
        <router-link to="/" custom v-slot="{navigate, isActive }">
          <NavButton
              @click="navigate"
              :isActive="isActive"
              :displayText="'AdList'"
          ></NavButton>
        </router-link>
        <router-link to="/statuslist" custom v-slot="{navigate, isActive }">
          <NavButton
              @click="navigate"
              :isActive="isActive"
              :displayText="'StatusList'"
          ></NavButton>
        </router-link>
        <router-link to="/adsearch" custom v-slot="{navigate, isActive }">
          <NavButton
              @click="navigate"
              :isActive="isActive"
              :displayText="'AdSearch'"
          ></NavButton>
        </router-link>
        <router-link :to="{name:'charsummary'}" custom v-slot="{navigate, isActive}">
          <NavButton
              @click="navigate"
              :isActive="isActive"
              :displayText="'CharSummary'"
          ></NavButton>
        </router-link>
        <router-link to="/favorites" custom v-slot="{navigate, isActive }">
          <NavButton
              @click="navigate"
              :isActive="isActive"
              :displayText="'Favorites'"
          ></NavButton>
        </router-link>
        <router-link to="/options" custom v-slot="{navigate, isActive }">
          <NavButton
              @click="navigate"
              :isActive="isActive"
              :displayText="'Options'"
          ></NavButton>
        </router-link>
        <router-link to="/faq" custom v-slot="{navigate, isActive }">
          <NavButton
              @click="navigate"
              :isActive="isActive"
              :displayText="'FAQ'"
          ></NavButton>
        </router-link>
      </div>
    </Sidebar>
  </div>
</template>

<script lang="ts">
import {computed, defineComponent, ref} from "vue";
import useBreakpoints from "../ts/reactiveHelper";
import NavButton from "./NavButton.vue";

export default defineComponent({
  name: "ffa-navbar",
  components: {NavButton},
  setup() {
    const sidebarActive = ref<boolean>(false);
    const reactive = useBreakpoints();
    const sidebarVisible = computed({
      get: () => sidebarActive.value && reactive.type.value === "mobile",
      set: (newVisibility: boolean) => {
        sidebarActive.value = newVisibility;
      },
    });
    return {
      sidebarActive,
      sidebarVisible,
      screenType: reactive.type,
    };
  },
});
</script>

<style lang="scss" scoped>
@import '@/scss/variables';
.logo {
  color: white;
  user-select: none;
  cursor: pointer;
}

.navbar {
  width: 100%;
  background-color: #212121;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
}

.sidebar {
  background-color: #212121;
}

.burger {
  color: white;
}
</style>