<template>
  <div>
    <div
      class="p-p-2 p-shadow-2 p-mr-3 p-text-center"
      :class="[options.nightMode ? 'night-mode':'day-mode', filter.paused ? 'paused-card-shadow' : '' ]"
    >
      <span class="p-text-bold"> {{ filter.name }} </span>
      <hr />
      <div class="p-d-flex p-jc-center">
        <div class="buttons">
          <i
            class="pi pi-user-edit card-icon edit-icon"
            @click="filterDialogVisibility = true"
          ></i>
          <i
            v-if="!filter.paused"
            class="pi pi-pause card-icon pause-icon"
            @click="pause(!filter.paused, filter.id)"
          ></i>
          <i
            v-else
            class="pi pi-play card-icon play-icon"
            @click="pause(!filter.paused, filter.id)"
          ></i>
          <i
            class="pi pi-times card-icon delete-icon"
            @click="displayConfirmation = true"
          ></i>
        </div>
      </div>
    </div>
    <template v-if="filterDialogVisibility">
      <FilterDialog
        v-model:visibility="filterDialogVisibility"
        :filterUuid="filter.id"
      ></FilterDialog>
    </template>
    <Dialog
      header="Delete Filter"
      v-model:visible="displayConfirmation"
      :style="{ width: '350px' }"
      :modal="true"
    >
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
        <span>Are you sure you want to delete this filter?</span>
      </div>
      <template #footer>
        <Button
          label="No"
          icon="pi pi-times"
          @click="displayConfirmation = false"
          class="p-button-text"
        />
        <Button
          label="Yes"
          icon="pi pi-check"
          @click="deleteFilter"
          class="p-button-text"
          autofocus
        />
      </template>
    </Dialog>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, ref } from "vue";
import { useStore } from "../store/store";
import { FilterSet } from "../store/state";
import FilterDialog from "./FilterDialog.vue";

export default defineComponent({
  name: "FilterCard",
  props: {
    filter: {
      type: Object as PropType<FilterSet>,
      required: true,
    },
  },
  components: {
    FilterDialog,
  },
  setup(props) {
    const store = useStore();
    const displayConfirmation = ref(false);
    const pause = store.pauseFilter;
    const filterDialogVisibility = ref(false);
    const options = store.getOptions
    return {
      options,
      deleteFilter: () => {
        store.deleteFilter(props.filter.id);
        displayConfirmation.value = false;
      },
      displayConfirmation,
      pause,
      filterDialogVisibility,
    };
  },
});
</script>

<style lang="scss" scoped>
@import '@/scss/variables';
hr {
  margin-top: 3px;
}

.night-mode{
  background-color: $night-main-bg;
  color: white;
}

.day-mode{
  background-color: $day-off-bg;
}

.paused-card-shadow {
  box-shadow: 0 0 5px red;
}


.card-icon {
  cursor: pointer;
  border-radius: 5px;
  padding: 5px;
  transition: 0.3s;
}

.edit-icon {
  color: #2196f3;
}

.edit-icon:hover {
  background-color: #c8e6ff;
}

.delete-icon {
  color: red;
}

.delete-icon:hover {
  background-color: rgb(255, 221, 221);
}

.pause-icon {
  color: #2196f3;
}

.pause-icon:hover {
  background-color: #c8e6ff;
}

.play-icon {
  color: #2196f3;
}

.play-icon:hover {
  background-color: #c8e6ff;
}

.buttons {
  display: grid;
  column-gap: 10px;
  grid-template-columns: 40px 40px 40px;
  border-radius: 4px;
  padding-top: 5px;
}
</style>
