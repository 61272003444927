<template>
  <Dialog
      :header="dialogModeCreate ? 'Create Filter' : 'Update Filter'"
      v-model:visible="visibilityRef"
      :modal="true"
      :contentStyle="{ overflow: 'auto' }"
  >
    <div class="p-d-flex p-jc-center">
      <InputText type="text" v-model="filterName" placeholder="Enter filter name"/>
    </div>
    <div v-if="dialogModeCreate" class="p-text-center p-pt-2 p-d-flex p-jc-center">
      <span>
        Select your desired character attributes here.<br/>
        Only ads / status matching your selection will be displayed.
      </span>
    </div>
    <div :class="[screentype === 'screen' ?  'filter-grid' : 'p-d-flex p-flex-column p-ai-center']">
      <FilterMultiselect
          :title="'Gender'"
          :availableOptions="availableGenders"
          :selectedItems="selectedGenders"
          :placeholder="'Select genders'"
          v-model:selecteditems="selectedGenders"
      ></FilterMultiselect>
      <FilterMultiselect
          :title="'Bodytype'"
          :availableOptions="availableBodytypes"
          :selectedItems="selectedBodytypes"
          :placeholder="'Select bodytypes'"
          v-model:selecteditems="selectedBodytypes"
      ></FilterMultiselect>
      <FilterMultiselect
          :title="'Orientation'"
          :availableOptions="availableOrientations"
          :placeholder="'Select orientations'"
          :selectedItems="selectedOrientations"
          v-model:selecteditems="selectedOrientations"
      ></FilterMultiselect>
      <FilterMultiselect
          :title="'Furry Preference'"
          :availableOptions="availableFurryPref"
          :selectedItems="selectedHumanFurryPref"
          :placeholder="'Select furry preference'"
          v-model:selecteditems="selectedHumanFurryPref"
      ></FilterMultiselect>
      <FilterMultiselect
          :title="'Dom-Sub'"
          :availableOptions="availableSubDom"
          :selectedItems="selectedDomSub"
          :placeholder="'Select dom/sub'"
          v-model:selecteditems="selectedDomSub"
      ></FilterMultiselect>
      <FilterMultiselect
          :title="'Channel'"
          :availableOptions="getFilterChannel().map(value => value.value)"
          :selectedItems="selectedChannel"
          :placeholder="'Select channels'"
          v-model:selecteditems="selectedChannel"
      ></FilterMultiselect>
      <FilterMultiselect
          :title="'Preferred Language'"
          :availableOptions="availableLanguagePref"
          :selectedItems="selectedPrefLanguage"
          :placeholder="'Select languages'"
          v-model:selecteditems="selectedPrefLanguage"
      ></FilterMultiselect>
      <FilterMultiselect
          :title="'Position'"
          :availableOptions="availablePosition"
          :selectedItems="selectedPosition"
          :placeholder="'Select position'"
          v-model:selecteditems="selectedPosition"
      ></FilterMultiselect>
      <div class="p-d-flex">
        <div>
          <div class="p-py-2 p-d-flex p-jc-between p-ai-center">
            <h3>Min Age</h3>
          </div>
          <InputNumber :min="0" :max="99999999" v-model="filterMinAge" :inputClass="'number-input'"></InputNumber>
        </div>
        <div class="p-pl-4">
          <div class="p-py-2 p-d-flex p-jc-between p-ai-center">
            <h3>Max Age</h3>
          </div>
          <InputNumber :min="0" :max="99999999" v-model="filterMaxAge" :inputClass="'number-input'"></InputNumber>
        </div>
      </div>
    </div>
    <div>
      <div>
        <div class="p-d-flex p-jc-center p-py-2">
          <h3>Kinks</h3>
        </div>
        <div :class="[screentype === 'screen' ?  'filter-grid' : 'p-d-flex p-flex-column p-ai-center p-jc-center']">
          <KinkFilterSelect :kink-filter="kinkfilter"
                            :kinks="kinks"
                            v-on:sectionToggle="kinkFilterToggle"
                            v-on:kinkListToggle="kinkListToggle"
                            v-on:deleteKinkFilter="deleteKinkFilter"
                            v-for="kinkfilter in selectedKinks" :key="kinkfilter.id"
                            :class="[screentype === 'screen' ?  '' : 'p-mt-3']"
          >

          </KinkFilterSelect>
          <div class="p-d-flex p-jc-center" :class="[screentype === 'screen' ?  '' : 'p-mt-3']">
            <Button
                icon="pi pi-plus"
                class="p-button-rounded  p-button-raised"
                @click="addKinkFilter"
            />
          </div>
        </div>
      </div>
    </div>

    <template #footer>
      <div class="p-pt-3">
        <Button
            v-if="!dialogModeCreate"
            label="Copy"
            icon="pi pi-copy"
            @click="copy()"
            class="p-button-text"
        />
        <Button
            label="Cancel"
            icon="pi pi-times"
            @click="closeEditFilter()"
            class="p-button-text"
        />
        <Button
            label="Save"
            icon="pi pi-check-square"
            @click="update()"
            class="p-button-text"
            autofocus
        />
      </div>
    </template>
  </Dialog>
</template>

<script lang="ts">
import {computed, defineComponent, ref, Slots} from "vue";
import {getFilterChannel,} from "../ts/FilterHelper";
import {useStore} from "../store/store";
import {FilterSet, KinkFilter} from "../store/state";
import {v4 as uuidv4} from "uuid";
import FilterMultiselect from "./FilterMultiselect.vue";
import useBreakpoints from "../ts/reactiveHelper"
import KinkFilterSelect from "@/components/KinkFilterSelect.vue";

interface SetupContext {
  slots: Slots;
  emit: (event: string, ...args: unknown[]) => void;
}

export default defineComponent({
  name: "FilterDialog",
  components: {
    FilterMultiselect,
    KinkFilterSelect
  },
  props: {
    visibility: {
      type: Boolean,
      required: true,
    },
    filterUuid: {
      type: String,
      required: true,
    },
  },
  setup(props, context: SetupContext) {
    const visibilityRef = computed({
      get: () => props.visibility,
      set: (newVisibility: boolean) => {
        context.emit("update:visibility", newVisibility);
      },
    });
    const breakpoint = useBreakpoints()
    const store = useStore();
    const filterName = ref("");
    const filterMinAge = ref<number | null>();
    const filterMaxAge = ref<number | null>();
    const selectedGenders = ref<Array<string>>([]);
    const selectedBodytypes = ref<Array<string>>([]);
    const selectedOrientations = ref<Array<string>>([]);
    const selectedHumanFurryPref = ref<Array<string>>([]);
    const selectedDomSub = ref<Array<string>>([]);
    const selectedChannel = ref<Array<string>>([]);
    const selectedPosition = ref<Array<string>>([]);
    const selectedPrefLanguage = ref<Array<string>>([]);
    const selectedKinks = ref<Array<KinkFilter>>([]);
    const kinks = computed(() => [...store.getKinks.value].sort((a, b) => {
      return a.name.localeCompare(b.name)
    }))
    const dialogModeCreate = computed(() => {
      return !props.filterUuid;
    });

    const kinkListToggle = (args: { id: string; kinkIds: string[] }) => {
      const kink = selectedKinks.value.find(value => {
        return value.id == args.id
      });
      if (kink) {
        kink.kinkIds = args.kinkIds.map(value => parseInt(value))
      }
    }
    const deleteKinkFilter = (id: string) => {
      selectedKinks.value = selectedKinks.value.filter(value => value.id !== id)
    }
    const kinkFilterToggle = (args: { id: string; section: string }) => {
      const kink = selectedKinks.value.find(value => {
        return value.id == args.id
      });
      if (kink) {
        switch (args.section) {
          case "fave": {
            kink.fave = !kink.fave;
            break;
          }
          case "yes": {
            kink.yes = !kink.yes;
            break;
          }
          case "maybe": {
            kink.maybe = !kink.maybe;
            break;
          }
          case "no": {
            kink.no = !kink.no;
            break;
          }
        }
      }
    }
    const closeEditFilter = () => {
      context.emit("update:visibility", false);
    };
    const existingFilter = store.getFilterByUuid(props.filterUuid);
    if (existingFilter) {
      filterName.value = existingFilter.name;
      selectedGenders.value = [...existingFilter.selectedGenders];
      selectedBodytypes.value = [...existingFilter.selectedBodytypes];
      selectedOrientations.value = [...existingFilter.selectedOrientations];
      selectedHumanFurryPref.value = [...existingFilter.selectedHumanFurryPref];
      selectedDomSub.value = [...existingFilter.selectedDomSub];
      selectedChannel.value = [...existingFilter.selectedChannel];
      selectedKinks.value = [...existingFilter.selectedKinks]
      selectedPosition.value = [...existingFilter.selectedPosition]
      selectedPrefLanguage.value = existingFilter.selectedLanguagePreference ? [...existingFilter.selectedLanguagePreference] : new Array<string>();
      filterMaxAge.value = existingFilter.maxAge ? existingFilter.maxAge : null;
      filterMinAge.value = existingFilter.minAge ? existingFilter.minAge : null;
      selectedKinks.value = [...existingFilter.selectedKinks].map(value => {
        return {...value}
      })
    }
    const addKinkFilter = () => {
      selectedKinks.value.push({
        id: uuidv4(),
        maybe: false,
        fave: false,
        yes: false,
        no: false,
        kinkIds: []
      } as KinkFilter)
    }

    const kinkFilterIsValid = (kinkFilter: KinkFilter): boolean => {
      return kinkFilter.kinkIds.length > 0 && (kinkFilter.yes || kinkFilter.no || kinkFilter.maybe || kinkFilter.fave)
    }

    const upsert = (id: string, isCopy: boolean) => {
      const upsertFilter: FilterSet = {
        id: id,
        selectedGenders: new Set<string>(selectedGenders.value),
        selectedOrientations: new Set<string>(selectedOrientations.value),
        selectedBodytypes: new Set<string>(selectedBodytypes.value),
        selectedHumanFurryPref: new Set<string>(selectedHumanFurryPref.value),
        selectedDomSub: new Set<string>(selectedDomSub.value),
        selectedChannel: new Set<string>(selectedChannel.value),
        selectedLanguagePreference: new Set<string>(selectedPrefLanguage.value),
        selectedPosition: new Set<string>(selectedPosition.value),
        selectedKinks: new Set<KinkFilter>(selectedKinks.value.filter(kinkFilter => kinkFilterIsValid(kinkFilter))),
        name: filterName.value == "" ? "MyRandomFilter" : filterName.value + (isCopy ? " [Copy]" : ""),
        minAge: filterMinAge.value,
        maxAge: filterMaxAge.value,
        paused: false,
      };
      store.upsertFilter(upsertFilter);
      closeEditFilter();
    };

    const copy = () => {
      upsert(uuidv4(), true);
    };

    const update = () => {
      upsert(props.filterUuid ? props.filterUuid : uuidv4(), false);
    };

    const availableOrientations = computed(
        () => store.getOrientations.value,
    );
    const availableBodytypes = computed(
        () => store.getBodytypes.value,
    );

    const availableGenders = computed(
        () => store.getGenders.value,
    );

    const availableFurryPref = computed(
        () => store.getFurryPref.value,
    );
    const availableSubDom = computed(
        () => store.getSubDom.value,
    );

    const availableLanguagePref = computed(
        () => store.getLanguagePref.value,
    );

    const availablePosition = computed(
        () => store.getPosition.value,
    );

    return {
      screentype: breakpoint.type,
      visibilityRef,
      closeEditFilter,
      dialogModeCreate,
      filterName,
      selectedGenders,
      availableGenders,
      selectedBodytypes,
      availableBodytypes,
      selectedOrientations,
      availableOrientations,
      availableFurryPref,
      availablePosition,
      availableSubDom,
      getFilterChannel,
      selectedHumanFurryPref,
      selectedPrefLanguage,
      selectedPosition,
      selectedDomSub,
      selectedChannel,
      availableLanguagePref,
      update,
      copy,
      filterMinAge,
      filterMaxAge,
      selectedKinks,
      kinkFilterToggle,
      kinks,
      kinkListToggle,
      deleteKinkFilter,
      addKinkFilter
    };
  },
});
</script>

<style>
.number-input {
  width: 100px;
}

.filter-grid {
  display: grid;
  word-wrap: break-word;
  column-gap: 20px;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: minmax(100px, auto);
}
</style>