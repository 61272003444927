<template>
  <div class="p-d-flex p-jc-center p-p-5 p-flex-wrap">
    <Card class="p-m-2" style="width: 30rem; margin-bottom: 2em"  :class="[options.nightMode ? 'night':'day' ]">
      <template #title> What is this about? </template>
      <template #content>
        <p>
          This app is allowing you to filter ads for roleplays from the
          <a target="_blank" href="https://www.f-list.net">F-List</a>
          website. The designated roleplay channels are usually very spammy and
          depending on what you are looking for most of the ads are of no
          interest to you. FFA allows you to blend these out by using filters,
          making it easier to find those ads you could actually be interested
          in. With the AdSearch it also offers you to retroactively check ads
          posted in the past 72 hours. TL;DR hopefully this helps you find
          roleplay partners more easily!
        </p>
      </template>
    </Card>

    <Card class="p-m-2" style="width: 30rem; margin-bottom: 2em"  :class="[options.nightMode ? 'night':'day' ]">
      <template #title> How does the AdList work? </template>
      <template #content>
        <p>
          In the 'Adlist' section you will get a live reposting on all roleplay
          ads being posted in the Sex Driven - or Story Driven LFRP channels in
          the F-List chat. Only ads that match at least one of your filters (if
          you have any in place) will get displayed. Older ads will at some
          point 'drop off the bottom of the list' with new ones always being
          added to the top.
        </p>
      </template>
    </Card>
    <Card class="p-m-2" style="width: 30rem; margin-bottom: 2em"  :class="[options.nightMode ? 'night':'day' ]">
      <template #title> What does show fresh ads only mean? </template>
      <template #content>
        <p>
          There is a lot of 'noise' in the ad channels coming from characters
          posting the exact same ad for weeks, months (years?!). This new
          option helps filtering all these out with one click. When this AdList
          option is checked, only ads that premiered (were posted for the first time) no
          longer than a week ago will be shown. All other active filters apply on top of
          this selection.
        </p>
      </template>
    </Card>
    <Card class="p-m-2" style="width: 30rem; margin-bottom: 2em"  :class="[options.nightMode ? 'night':'day' ]">
      <template #title
        >Why are some Ads 'jumping' to the top of the AdList?</template
      >
      <template #content>
        <p>
          This is a combination of using the 'Filter Duplicates' option and
          people posting the same ad in quick succession in multiple different
          channels. If you filter duplicates, the same ad posted multiple times
          will only show up in your AdList once (from the channel it was last
          posted). If somebody now posts the same Ad with a 2 second delay in
          multiple channels, each time he does so it will be shown at the top of
          the list as a new entry while the old (now duplicate) Ad entry is
          removed. This creates that 'jumping' effect, when that happens you
          will see the channel where the Ad was posted constantly change around.
        </p>
      </template>
    </Card>
    <Card class="p-m-2" style="width: 30rem; margin-bottom: 2em"  :class="[options.nightMode ? 'night':'day' ]">
      <template #title> How does the StatusList work? </template>
      <template #content>
        <p>
          The 'Statuslist' is a live display of all characters who are online in
          the chat, have their status set to 'looking' and match at least one of
          your filters (if you have any in place). The list is kept up to date
          automatically, with characters that go offline or change their status
          away from 'looking', will disappear from the list. A maximum of 150
          characters is displayed.
        </p>
      </template>
    </Card>
    <Card class="p-m-2" style="width: 30rem; margin-bottom: 2em"  :class="[options.nightMode ? 'night':'day' ]">
      <template #title> How does the AdSearch work? </template>
      <template #content>
        <p>
          With the ad search you can find ads posted in the past 72 hours
          filtered by the same attributes as for the Lists plus the possibility
          to also include one or several keywords that have to appear in the ad.
          Any keywords mode means that an ad will pass if it includes one or
          more of the keywords you selected. All keywords mode means that an ad
          will pass if all the keywords you have selected are appearing in it.
          Keywords are case insensitive and have to be separated by comma.
        </p>
      </template>
    </Card>
    <Card class="p-m-2" style="width: 30rem; margin-bottom: 2em"  :class="[options.nightMode ? 'night':'day' ]">
      <template #title> How does the Character Summary work? </template>
      <template #content>
        <p>
          The character summary allows you to find past ads or looking status 
          (with text) for a specific character. Just type in the (exact) name 
          of the character and see if they were looking for something you like! A
          maximum of 15 results each are displayed.
        </p>
      </template>
    </Card>
    <Card class="p-m-2" style="width: 30rem; margin-bottom: 2em"  :class="[options.nightMode ? 'night':'day' ]">
      <template #title>
        Who to contact for questions, feedback, requests?
      </template>
      <template #content>
        <p>
          Leon Strasky via Chat / Notes or FFA Bot via Notes only. FFA Bot is
          the bot character who should be online 24/7 in the chat. He's not very
          talkactive but you can issue a few commands to him. For a list of
          available commands, PM him with !commands. Feedback is
          <b>always</b> apprechiated so anything that you dislike about the app
          or think would work better differently. Let me know per note to
          <a href="https://www.f-list.net/c/FFA%20Bot/" target="_blank"
            >FFA Bot</a
          >
          or directly to
          <a href="https://www.f-list.net/c/Leon%20Strasky" target="_blank"
            >Leon Strasky</a
          >
          if you catch me online in the chat!
        </p>
      </template>
    </Card>
    <Card class="p-m-2" style="width: 30rem; margin-bottom: 2em"  :class="[options.nightMode ? 'night':'day' ]">
      <template #title> How can I support? </template>
      <template #content>
        <p>
          Two ways in which you can support FFA! First is to spread the word
          about this tool. There is a certain network-effect involved and the
          more people use it, the better it gets for everyone. Since it doesn't
          just allow you to more easily find others, it also allows others to
          more easily find you. A win-win for everyone! I'm always looking for
          feedback no matter how blunt or small it is. Something you dislike,
          something you think would be better in a different way, some new feature
          that you feel would support in finding partners. Please let me know!
        </p>
      </template>
    </Card>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import {useStore} from "@/store/store";

export default defineComponent({
  name: "FaqView",
  components: {},
  setup() {
    const store = useStore()
    const options = store.getOptions
    return {options};
  },
});
</script>
<style lang="scss" scoped>
@import '@/scss/variables';
</style>