<template>
  <div>
    <div class="p-d-flex p-ai-center p-flex-column" :class="[options.nightMode ? 'night-text':'day-text' ]">
      <h1 class="p-pb-3 font-bowlby">Favorites</h1>
      <div v-if="faves.length == 0">
        You have no favorite ads. You can select some by clicking the <i
          class="pi pi-heart"
        ></i>
        icon on an ad.
      </div>
    </div>

    <Ad
      class="p-shadow-2"
      v-for="ad in faves"
      :key="ad.key"
      :ad="ad"
      mode="favorites"
    >
    </Ad>
    <div v-if="faves.length > 0" class="p-d-flex p-jc-center">
      <Button
        class="p-mt-2 p-button-danger"
        label="Clear all Favorites"
        icon="pi pi-trash"
        iconPos="left"
        @click="clearFavorites()"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useStore } from "../store/store";
import Ad from "../components/Ad.vue";

export default defineComponent({
  name: "ffa-favorites",
  components: {
    Ad,
  },
  setup() {
    const store = useStore();
    const options = store.getOptions
    return {
      faves: store.getFavorites,
      clearFavorites: store.clearFavorites,
      options,
    };
  },
});
</script>
<style lang="scss" scoped>
@import '@/scss/variables';
</style>
