import {Ad, AdSearch, AdSearchResponseDto, CharacterSummaryResponseDto, FilterSet, KinkFilter, Status} from './state'
import {filterAdList, filterStatusList} from '../ts/FilterHelper'
import {computed, reactive} from 'vue'
import {FlistMappingData, Kink} from "@/ts/common";

export interface Options {
    hideAvatars: boolean;
    timeOnly: boolean;
    filterDuplicates: boolean;
    ignoreList: Array<string>;
    ignoreSet: Set<string>;
    adListPaused: boolean;
    hideEicons: boolean;
    ignoreListSwapPerformed: boolean;
    filterSetSwapPerformed: boolean;
    freshAdsOnly: boolean;
    nightMode: boolean;
}

interface State {
    adList: Array<Ad>;
    pausedAdList: Array<Ad>;
    statusList: Array<Status>;
    filterSets: Array<FilterSet>;
    favorites: Array<Ad>;
    adSearches: Array<AdSearch>;
    options: Options;
    lastAdSearchResponse: AdSearchResponseDto | null;
    lastCharacterSummaryResponse: CharacterSummaryResponseDto | null;
    maxConnectionError: boolean;
    flistMappingData: FlistMappingData | null;
}

const state = reactive<State>({
    adList: [],
    statusList: [],
    filterSets: [],
    pausedAdList: [],
    favorites: [],
    flistMappingData: null,
    adSearches: [],
    options: {
        hideAvatars: true,
        filterDuplicates: true,
        timeOnly: false,
        ignoreList: [],
        ignoreSet: new Set<string>(),
        adListPaused: false,
        hideEicons: true,
        ignoreListSwapPerformed: false,
        filterSetSwapPerformed: false,
        freshAdsOnly: false,
        nightMode: false,
    },
    lastAdSearchResponse: null,
    lastCharacterSummaryResponse: null,
    maxConnectionError: false

})

window.addEventListener('beforeunload', () => {
    localStorage.setItem("ffa.filtersets", JSON.stringify(state.filterSets, (key, value) => key.startsWith('selected') ? [...value] : value))
    localStorage.setItem("ffa.options", JSON.stringify(state.options, (key, value) => value instanceof Set ? [...value] : value))
    localStorage.setItem("ffa.favorites", JSON.stringify(state.favorites))
    localStorage.setItem("ffa.adsearches", JSON.stringify(state.adSearches))
});
const ffaFilterSets = localStorage.getItem("ffa.filtersets")
const ffaOptions = localStorage.getItem("ffa.options")
const ffaFavorites = localStorage.getItem("ffa.favorites")
const ffaAdSearches = localStorage.getItem("ffa.adsearches")
if (ffaAdSearches) {
    state.adSearches = JSON.parse(ffaAdSearches)
    for (const adSearch of state.adSearches) {
        adSearch.saveTime = new Date(adSearch.saveTime)
    }
}
if (ffaFavorites) {
    state.favorites = JSON.parse(ffaFavorites)
}
if (ffaFilterSets) {
    const parsedFilterSets: Array<FilterSet> = JSON.parse(ffaFilterSets, (key, value) => key.startsWith('selected') ? new Set<string>(value) : value)
    state.filterSets = parsedFilterSets
    for (const filterSet of parsedFilterSets) {
        filterSet.selectedBodytypes = filterSet.selectedBodytypes ? filterSet.selectedBodytypes : new Set<string>();
        filterSet.selectedChannel = filterSet.selectedChannel ? filterSet.selectedChannel : new Set<string>();
        filterSet.selectedDomSub = filterSet.selectedDomSub ? filterSet.selectedDomSub : new Set<string>();
        filterSet.selectedGenders = filterSet.selectedGenders ? filterSet.selectedGenders : new Set<string>();
        filterSet.selectedHumanFurryPref = filterSet.selectedHumanFurryPref ? filterSet.selectedHumanFurryPref : new Set<string>();
        filterSet.selectedLanguagePreference = filterSet.selectedLanguagePreference ? filterSet.selectedLanguagePreference : new Set<string>();
        filterSet.selectedPosition = filterSet.selectedPosition ? filterSet.selectedPosition : new Set<string>();
        filterSet.selectedOrientations = filterSet.selectedOrientations ? filterSet.selectedOrientations : new Set<string>();
        filterSet.selectedKinks = filterSet.selectedKinks ? filterSet.selectedKinks : new Set<KinkFilter>();
    }
}
if (ffaOptions) {
    const parsedOptions: Options = JSON.parse(ffaOptions, (key, value) => key == 'ignoreSet' ? new Set<string>(value) : value)
    state.options = {
        ...{
            hideAvatars: false,
            timeOnly: true,
            ignoreList: [],
            adListPaused: false,
            ignoreSet: new Set<string>(),
            filterSetSwapPerformed: false
        }, ...parsedOptions
    }
    state.options.adListPaused = false
    //one time set conversion
    if (!state.options.ignoreListSwapPerformed) {
        state.options.ignoreSet = new Set<string>(state.options.ignoreList)
        state.options.ignoreListSwapPerformed = true
    }
}

const favoriteFunctions = {
    getFavorites: computed((): Array<Ad> => {
        return state.favorites
    }),
    pushFavorite: (fave: Ad) => {
        const faveExists = state.favorites.find(f => f.key == fave.key)
        if (!faveExists) {
            state.favorites.unshift(fave)
        }
    },
    removeFavorite: (noFave: Ad) => state.favorites = state.favorites.filter(a => a.key !== noFave.key),
    clearFavorites: () => state.favorites = []
}

const characterSummaryFunctions = {
    setCharacterSummary: (characterSummary: CharacterSummaryResponseDto) => state.lastCharacterSummaryResponse = characterSummary,
    getCharacterSummary: computed(() => state.lastCharacterSummaryResponse),
}

const adSearchFunctions = {
    setAdSearch: (adSearch: AdSearchResponseDto) => state.lastAdSearchResponse = adSearch,
    getAdSearch: computed(() => state.lastAdSearchResponse),
    saveAdSearches: (adSearch: AdSearch) => state.adSearches.unshift(adSearch),
    deleteAdSearch: (id: string) => state.adSearches = state.adSearches.filter(value => value.id !== id),
    getAdSearches: computed(() => state.adSearches),
}

const optionsFunctions = {
    pushIgnore: (ignore: string) => {
        state.options.ignoreSet.add(ignore)
        if (state.lastAdSearchResponse) {
            state.lastAdSearchResponse.ads = state.lastAdSearchResponse.ads.filter(s => s.characterName !== ignore)
        }
    }
    ,
    removeIgnore: (remove: string) => state.options.ignoreSet.delete(remove),
    purgeIgnore: () => state.options.ignoreSet = new Set<string>(),
    getOptions: computed((): Options => state.options),
    setHideAvatars: (v: boolean) => state.options.hideAvatars = v,
    setHideEicons: (v: boolean) => state.options.hideEicons = v,
    setTimeOnly: (v: boolean) => state.options.timeOnly = v,
    setPauseAdlist: (v: boolean) => state.options.adListPaused = v,
    setFreshAdsOnly: (v: boolean) => state.options.freshAdsOnly = v,
    setFilterDuplicates: (v: boolean) => state.options.filterDuplicates = v,
    setNightMode: (v: boolean) => state.options.nightMode = v,
    setMaxConnError: (v: boolean) => state.maxConnectionError = v,
    getMaxConnError: computed((): boolean => state.maxConnectionError)
}

const filterFunctions = {
    deleteStatus: (charName: string) => state.statusList = state.statusList.filter(s => s.characterName !== charName),
    upsertFilter: (filter: FilterSet) => {
        const filterIndex = state.filterSets.findIndex(f => f.id === filter.id)
        if (filterIndex !== -1) {
            state.filterSets = [...state.filterSets.map(f => f.id !== filter.id ? f : filter)]
        } else {
            state.filterSets.push(filter)
        }
    },
    getFilterByUuid: (uuid: string): FilterSet | undefined => state.filterSets.find(f => f.id == uuid),
    getFilters: computed((): Array<FilterSet> => state.filterSets),
    deleteFilter: (uuid: string) => state.filterSets = state.filterSets.filter(f => f.id !== uuid),
    pauseFilter: (pauseFlag: boolean, uuid: string) => {
        const filterToPause = state.filterSets.find(f => f.id == uuid)
        if (filterToPause) {
            filterToPause.paused = pauseFlag
        }
    }
}

const exportListItems = (key: string, addNoInfo: boolean): string[] => {
    if (state.flistMappingData) {
        const listItems = state.flistMappingData.listitems
            .filter(value => value.name === key)
            .map(value => value.value)
        listItems.sort()
        if (addNoInfo) {
            listItems.push('No Info')
        }
        return listItems
    } else {
        return [];
    }
}

export function useStore() {
    return {
        ...favoriteFunctions,
        ...adSearchFunctions,
        ...characterSummaryFunctions,
        ...optionsFunctions,
        ...filterFunctions,
        pushAd: (ad: Ad) => {
            state.adList.unshift(ad)
            state.adList = state.adList.slice(0, 1000)
        },
        setStatusListInitially: (statusList: Array<Status>) => {
            state.statusList = statusList
        },
        pushStatus: (status: Status) => {
            const statusIndex = state.statusList.findIndex(f => f.characterName === status.characterName)
            if (statusIndex !== -1) {
                state.statusList = state.statusList.filter(s => s.characterName !== status.characterName)
            }
            state.statusList.unshift(status)
        },
        setFlistMappingData: (data: FlistMappingData) => {
            state.flistMappingData = data
        },
        getKinks: computed((): Kink[] => state.flistMappingData ? state.flistMappingData.kinks : []),
        getOrientations: computed((): string[] => state.flistMappingData ? exportListItems('orientation', true) : []),
        getGenders: computed((): string[] => state.flistMappingData ? exportListItems('gender', true) : []),
        getBodytypes: computed((): string[] => state.flistMappingData ? exportListItems('bodytype', true) : []),
        getFurryPref: computed((): string[] => state.flistMappingData ? exportListItems('furrypref', true) : []),
        getSubDom: computed((): string[] => state.flistMappingData ? exportListItems('subdom', true) : []),
        getLanguagePref: computed((): string[] => state.flistMappingData ? exportListItems('languagepreference', true) : []),
        getPosition: computed((): string[] => state.flistMappingData ? exportListItems('position', true) : []),
        getAds: computed((): Array<Ad> => state.adList),
        isAdListEmpty: computed((): boolean => state.adList.length == 0),
        isStatusListEmpty: computed((): boolean => state.statusList.length == 0),
        getFilteredAds: computed((): Array<Ad> => {
            if (!state.options.adListPaused) {
                state.pausedAdList = filterAdList(state.adList, state.filterSets, state.options.ignoreSet, state.options)
                return state.pausedAdList
            }
            return filterAdList(state.pausedAdList, state.filterSets, state.options.ignoreSet, state.options)
        }),
        getStatus: computed((): Array<Status> => state.statusList),
        getFilteredStatus: computed((): Array<Status> => filterStatusList(state.statusList, state.filterSets, state.options.ignoreSet)),
        clearAds: () => state.adList = [],
    }
}