import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, createBlock as _createBlock, normalizeClass as _normalizeClass, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "p-d-flex p-ai-center p-flex-column full-size" }
const _hoisted_2 = { class: "p-d-flex p-flex-column p-ai-center" }
const _hoisted_3 = { class: "p-mt-3" }
const _hoisted_4 = { class: "p-mt-3" }
const _hoisted_5 = { class: "p-mt-3" }
const _hoisted_6 = { class: "p-mt-3" }
const _hoisted_7 = { class: "p-pt-3 p-pb-2" }
const _hoisted_8 = { class: "p-d-flex p-flex-wrap p-jc-center" }
const _hoisted_9 = { key: 0 }
const _hoisted_10 = { key: 0 }
const _hoisted_11 = {
  key: 2,
  class: "p-pt-2"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ToggleButton = _resolveComponent("ToggleButton")!
  const _component_Chip = _resolveComponent("Chip")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Card = _resolveComponent("Card")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Card, {
      style: {"margin-bottom":"2em"},
      class: _normalizeClass([_ctx.options.nightMode ? 'night':'day' ])
    }, {
      title: _withCtx(() => _cache[7] || (_cache[7] = [
        _createElementVNode("div", { class: "p-d-flex p-flex-wrap p-jc-center" }, "Options", -1)
      ])),
      content: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_ToggleButton, {
            modelValue: _ctx.hideavatars,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.hideavatars) = $event)),
            onLabel: "Avatars displayed",
            offLabel: "Avatars hidden",
            onIcon: "pi pi-eye",
            offIcon: "pi pi-eye-slash",
            style: {"width":"15em"}
          }, null, 8, ["modelValue"]),
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_ToggleButton, {
              modelValue: _ctx.hideeicons,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.hideeicons) = $event)),
              onLabel: "Eicons displayed",
              offLabel: "Eicons hidden",
              onIcon: "pi pi-eye",
              offIcon: "pi pi-eye-slash",
              style: {"width":"15em"}
            }, null, 8, ["modelValue"])
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_ToggleButton, {
              modelValue: _ctx.timeOnly,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.timeOnly) = $event)),
              onLabel: "Time only",
              offLabel: "Time + Date",
              onIcon: "pi pi-clock",
              offIcon: "pi pi-calendar",
              style: {"width":"15em"}
            }, null, 8, ["modelValue"])
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_ToggleButton, {
              modelValue: _ctx.filterDuplicates,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.filterDuplicates) = $event)),
              onLabel: "Filter duplicates",
              offLabel: "Show duplicates",
              onIcon: "pi pi-filter",
              offIcon: "pi pi-filter-slash",
              style: {"width":"15em"}
            }, null, 8, ["modelValue"])
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_ToggleButton, {
              modelValue: _ctx.nightMode,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.nightMode) = $event)),
              onLabel: "Night Mode On",
              offLabel: "Night Mode Off",
              onIcon: "pi pi-moon",
              offIcon: "pi pi-sun",
              style: {"width":"15em"}
            }, null, 8, ["modelValue"])
          ]),
          _createElementVNode("h3", _hoisted_7, "Ignore List (" + _toDisplayString(_ctx.fullIgnores.size) + ")", 1),
          _createElementVNode("div", _hoisted_8, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.limitedIgnores, (ignore) => {
              return (_openBlock(), _createElementBlock("div", {
                key: ignore,
                class: "p-m-1"
              }, [
                _createVNode(_component_Chip, {
                  label: ignore,
                  removable: "",
                  onRemove: ($event: any) => (_ctx.removeIgnore(ignore))
                }, null, 8, ["label", "onRemove"])
              ]))
            }), 128)),
            (_ctx.limitedIgnores.length == 0 || _ctx.limitedIgnores.length == undefined)
              ? (_openBlock(), _createElementBlock("div", _hoisted_9, _cache[8] || (_cache[8] = [
                  _createTextVNode(" Your ignore list is empty. You can ignore/hide all ads and status messages from a character by clicking the "),
                  _createElementVNode("i", { class: "pi pi-eye-slash" }, null, -1),
                  _createTextVNode(" icon. ")
                ])))
              : _createCommentVNode("", true)
          ]),
          (_ctx.limitIgnores && _ctx.fullIgnores.size > 35)
            ? (_openBlock(), _createElementBlock("div", _hoisted_10, _cache[9] || (_cache[9] = [
                _createElementVNode("span", null, "Not all your ignores are being displayed.", -1)
              ])))
            : _createCommentVNode("", true),
          (_ctx.limitIgnores && _ctx.fullIgnores.size > 35)
            ? (_openBlock(), _createBlock(_component_Button, {
                key: 1,
                style: {"margin-top":"10px"},
                label: "Show all",
                onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.showAllIgnores()))
              }))
            : _createCommentVNode("", true),
          (_ctx.limitedIgnores.length != 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                _createVNode(_component_Button, {
                  icon: "pi pi-trash",
                  label: "Remove all Ignores",
                  class: "p-button-danger",
                  onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.purgeIgnore()))
                })
              ]))
            : _createCommentVNode("", true)
        ])
      ]),
      _: 1
    }, 8, ["class"])
  ]))
}