<template>
  <div>
    <FilterList></FilterList>
    <div v-if="statusListEmpty" class="p-d-flex p-jc-center p-pt-2">
      <ProgressBar mode="indeterminate" style="height: 0.5em; width: 18rem" />
    </div>
    <div v-if="!statusListEmpty && getStatus.length === 0" class="p-d-flex p-jc-center p-pt-2" :class="[options.nightMode ? 'night-text':'day-text' ]">
      Sorry there are no statuses matching your filters :(
    </div>
    <div class="statuslist">
      <Status v-for="ad in getStatus" v-bind:key="ad.key" v-bind:ad="ad">
      </Status>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useStore } from "../store/store";
import Status from "../components/Status.vue";
import FilterList from "../components/FilterList.vue";

export default defineComponent({
  name: "StatusView",
  components: {
    Status,
    FilterList,
  },
  setup() {
    const store = useStore();
    const options = store.getOptions
    return {
      options,
      statusListEmpty: store.isStatusListEmpty,
      getStatus: store.getFilteredStatus,
    };
  },
});
</script>


<style lang="scss" scoped>
@import '@/scss/variables';
.statuslist {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(340px, 1fr));
  column-gap: 5px;
}
</style>
