import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "p-d-flex p-jc-center p-mt-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createBlock(_component_Dialog, {
    header: "Save AdSearch",
    visible: _ctx.visibilityRef,
    "onUpdate:visible": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.visibilityRef) = $event)),
    modal: true,
    contentStyle: { overflow: 'auto' }
  }, {
    footer: _withCtx(() => [
      _createVNode(_component_Button, {
        label: "Cancel",
        icon: "pi pi-times",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.cancel())),
        class: "p-button-text"
      }),
      _createVNode(_component_Button, {
        disabled: !_ctx.adSearchName,
        label: "Save",
        icon: "pi pi-check-square",
        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.save())),
        class: "p-button-text",
        autofocus: ""
      }, null, 8, ["disabled"])
    ]),
    default: _withCtx(() => [
      _cache[4] || (_cache[4] = _createElementVNode("div", { class: "p-text-center p-pt-2 p-d-flex p-jc-center" }, [
        _createElementVNode("span", null, [
          _createTextVNode(" Enter a name under which you want to save your currently selected AdSearch."),
          _createElementVNode("br")
        ])
      ], -1)),
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_InputText, {
          type: "text",
          modelValue: _ctx.adSearchName,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.adSearchName) = $event)),
          placeholder: "Enter AdSearch name"
        }, null, 8, ["modelValue"])
      ])
    ]),
    _: 1
  }, 8, ["visible"]))
}