<template>
  <Dialog
      header="Saved Ad Searches"
      v-model:visible="visibilityRef"
      :modal="true"
      :contentStyle="{ overflow: 'auto' }"
  >
    <div>
      <DataTable v-if="savedAdSearches.length > 0" :value="savedAdSearches" responsiveLayout="scroll">
        <Column field="adSearchName" header="Name"></Column>
        <Column field="saveTime" header="Saved At">
          <template #body="slotProps">
            {{slotProps.data.saveTime.toLocaleString()}}
          </template>
        </Column>
        <Column field="id" header="Actions">
          <template #body="slotProps">
            <i v-tooltip="'Load AdSearch'" class="pi pi-file load" @click="loadAd(slotProps.data.id)"></i>
            <i v-tooltip="'Delete AdSearch'" class="p-pl-2 pi pi-times delete" @click="deleteAd(slotProps.data.id)"></i>
          </template>
        </Column>
      </DataTable>
      <div v-else>You currently have no saved AdSearches.</div>
    </div>
    <template #footer>
      <Button
          label="Cancel"
          icon="pi pi-times"
          @click="cancel()"
          class="p-button-text"
      />
    </template>
  </Dialog>
</template>

<script lang="ts">
import {computed, defineComponent, PropType, Slots} from "vue";
import {AdSearch} from "@/store/state";


interface SetupContext {
  slots: Slots;
  emit: (event: string, ...args: unknown[]) => void;
}

export default defineComponent({
  name: "AdSearchLoadDialog",
  components: {},
  props: {
    visibility: {
      type: Boolean,
      required: true,
    },
    savedAdSearches: {
      type: Object as PropType<AdSearch[]>,
      required: true,
    },
  },
  setup(props, context: SetupContext) {

    const loadAd = (id: string) => {
      context.emit("load", id)
    }

    const deleteAd = (id: string) => {
      context.emit("delete", id)
    }

    const visibilityRef = computed({
      get: () => props.visibility,
      set: (newVisibility: boolean) => {
        context.emit("update:visibility", newVisibility);
      },
    });

    const cancel = () => {
      context.emit("update:visibility", false);
    };

    return {
      visibilityRef,
      cancel,
      loadAd,
      deleteAd
    };
  },
});
</script>

<style>
i{
  cursor:pointer;
}
i.load:hover{
  color: #2196f3;
}
i.delete:hover{
  color: red;
}
</style>