import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createBlock as _createBlock, createVNode as _createVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "p-text-bold" }
const _hoisted_2 = { class: "p-d-flex p-jc-center" }
const _hoisted_3 = { class: "buttons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FilterDialog = _resolveComponent("FilterDialog")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", {
      class: _normalizeClass(["p-p-2 p-shadow-2 p-mr-3 p-text-center", [_ctx.options.nightMode ? 'night-mode':'day-mode', _ctx.filter.paused ? 'paused-card-shadow' : '' ]])
    }, [
      _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.filter.name), 1),
      _cache[7] || (_cache[7] = _createElementVNode("hr", null, null, -1)),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("i", {
            class: "pi pi-user-edit card-icon edit-icon",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.filterDialogVisibility = true))
          }),
          (!_ctx.filter.paused)
            ? (_openBlock(), _createElementBlock("i", {
                key: 0,
                class: "pi pi-pause card-icon pause-icon",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.pause(!_ctx.filter.paused, _ctx.filter.id)))
              }))
            : (_openBlock(), _createElementBlock("i", {
                key: 1,
                class: "pi pi-play card-icon play-icon",
                onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.pause(!_ctx.filter.paused, _ctx.filter.id)))
              })),
          _createElementVNode("i", {
            class: "pi pi-times card-icon delete-icon",
            onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.displayConfirmation = true))
          })
        ])
      ])
    ], 2),
    (_ctx.filterDialogVisibility)
      ? (_openBlock(), _createBlock(_component_FilterDialog, {
          key: 0,
          visibility: _ctx.filterDialogVisibility,
          "onUpdate:visibility": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.filterDialogVisibility) = $event)),
          filterUuid: _ctx.filter.id
        }, null, 8, ["visibility", "filterUuid"]))
      : _createCommentVNode("", true),
    _createVNode(_component_Dialog, {
      header: "Delete Filter",
      visible: _ctx.displayConfirmation,
      "onUpdate:visible": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.displayConfirmation) = $event)),
      style: { width: '350px' },
      modal: true
    }, {
      footer: _withCtx(() => [
        _createVNode(_component_Button, {
          label: "No",
          icon: "pi pi-times",
          onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.displayConfirmation = false)),
          class: "p-button-text"
        }),
        _createVNode(_component_Button, {
          label: "Yes",
          icon: "pi pi-check",
          onClick: _ctx.deleteFilter,
          class: "p-button-text",
          autofocus: ""
        }, null, 8, ["onClick"])
      ]),
      default: _withCtx(() => [
        _cache[8] || (_cache[8] = _createElementVNode("div", { class: "confirmation-content" }, [
          _createElementVNode("i", {
            class: "pi pi-exclamation-triangle p-mr-3",
            style: {"font-size":"2rem"}
          }),
          _createElementVNode("span", null, "Are you sure you want to delete this filter?")
        ], -1))
      ]),
      _: 1
    }, 8, ["visible"])
  ]))
}