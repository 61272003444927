
import XBBCODE from "../js/xbbcode.js"
import {Ad, Status, SharedMessageValues} from '../store/state'
import { v4 as uuidv4 } from 'uuid';

const PARAMETER_DEFAULT_VALUE = "No Info"


function setProperties(ad: SharedMessageValues) {
    ad.characterBodytype = ad.characterBodytype ? ad.characterBodytype : PARAMETER_DEFAULT_VALUE
    ad.characterDomsub = ad.characterDomsub ? ad.characterDomsub : PARAMETER_DEFAULT_VALUE
    ad.characterFurryPreference = ad.characterFurryPreference ? ad.characterFurryPreference : PARAMETER_DEFAULT_VALUE
    ad.characterGender = ad.characterGender ? ad.characterGender : PARAMETER_DEFAULT_VALUE
    ad.characterOrientation = ad.characterOrientation ? ad.characterOrientation : PARAMETER_DEFAULT_VALUE
    ad.characterSpecies = ad.characterSpecies ? ad.characterSpecies : PARAMETER_DEFAULT_VALUE
    ad.characterLanguagePreference = ad.characterLanguagePreference ? ad.characterLanguagePreference : PARAMETER_DEFAULT_VALUE
    ad.characterPosition = ad.characterPosition ? ad.characterPosition : PARAMETER_DEFAULT_VALUE
    ad.characterAge = ad.characterAge ? ad.characterAge : null
    ad.faveKinks = ad.faveKinks ? new Set<number>(ad.faveKinks) : new Set<number>();
    ad.yesKinks = ad.yesKinks ? new Set<number>(ad.yesKinks) : new Set<number>();
    ad.maybeKinks = ad.maybeKinks ? new Set<number>(ad.maybeKinks) : new Set<number>();
    ad.noKinks = ad.noKinks ? new Set<number>(ad.noKinks) : new Set<number>();
}

export function getXBBEncodedMessage(message: string): string{
    message = message ? message : ""
    const bbencoded = XBBCODE.process({
        text: message,
        addInLineBreaks: false,
        removeMisalignedTags: true
    })
    return bbencoded.html
    
}

export function enrichRawStatusDelta(statusDelta: Status): Status {
    statusDelta.key = uuidv4()
    setProperties(statusDelta)
    statusDelta.parsedMessage = getXBBEncodedMessage(statusDelta.statusMessage)
    return statusDelta
}

export function enrichRawAd(ad: Ad): Ad {
    ad.key = uuidv4()
    setProperties(ad)
    ad.parsedMessage = getXBBEncodedMessage(ad.adMessage)
    return ad
}

