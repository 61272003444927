<template>
  <div>
    <div class="p-d-flex p-jc-center">
      <h2 class="p-pb-3 font-bowlby" :class="[options.nightMode ? 'night-text':'day-text' ]">Ad Search</h2>
    </div>
    <div class="p-d-flex p-jc-center p-flex-wrap">
      <div class="p-p-2">
        <FilterMultiselect
            :night-mode="options.nightMode"
            :title="'Gender'"
            :availableOptions="availableGenders"
            :selectedItems="selectedGenders"
            :placeholder="'Select genders'"
            v-model:selecteditems="selectedGenders"
        ></FilterMultiselect>
      </div>
      <div class="p-p-2">
        <FilterMultiselect
            :night-mode="options.nightMode"
            :title="'Bodytype'"
            :availableOptions="availableBodytypes"
            :selectedItems="selectedBodytypes"
            :placeholder="'Select bodytypes'"
            v-model:selecteditems="selectedBodytypes"
        ></FilterMultiselect>
      </div>
      <div class="p-p-2">
        <FilterMultiselect
            :night-mode="options.nightMode"
            :title="'Orientation'"
            :availableOptions="availableOrientations"
            :selectedItems="selectedOrientations"
            :placeholder="'Select orientations'"
            v-model:selecteditems="selectedOrientations"
        ></FilterMultiselect>
      </div>
      <div class="p-p-2">
        <FilterMultiselect
            :night-mode="options.nightMode"
            :title="'Furry Preference'"
            :availableOptions="availableFurryPref"
            :selectedItems="selectedHumanFurryPref"
            :placeholder="'Select furry preference'"
            v-model:selecteditems="selectedHumanFurryPref"
        ></FilterMultiselect>
      </div>
      <div class="p-p-2">
        <FilterMultiselect
            :night-mode="options.nightMode"
            :title="'Dom-Sub'"
            :availableOptions="availableSubDom"
            :selectedItems="selectedDomSub"
            :placeholder="'Select dom/sub'"
            v-model:selecteditems="selectedDomSub"
        ></FilterMultiselect>
      </div>
      <div class="p-p-2">
        <FilterMultiselect
            :night-mode="options.nightMode"
            :title="'Language Preference'"
            :availableOptions="availableLanguagePref"
            :selectedItems="selectedLanguagePref"
            :placeholder="'Select language'"
            v-model:selecteditems="selectedLanguagePref"
        ></FilterMultiselect>
      </div>
      <div class="p-p-2">
        <FilterMultiselect
            :night-mode="options.nightMode"
            :title="'Position'"
            :availableOptions="availablePosition"
            :selectedItems="selectedPosition"
            :placeholder="'Select position'"
            v-model:selecteditems="selectedPosition"
        ></FilterMultiselect>
      </div>
      <div class="p-p-2" :class="[options.nightMode ? 'night-text':'day-text' ]">
        <div class="p-d-flex">
          <div>
            <div class="p-py-2 p-d-flex p-jc-between p-ai-center" >
              <h3>Min Age</h3>
            </div>
            <InputNumber :min="0" :max="99999999" v-model="minAge" :inputClass="'number-input'"></InputNumber>
          </div>
          <div class="p-pl-4">
            <div class="p-py-2 p-d-flex p-jc-between p-ai-center">
              <h3>Max Age</h3>
            </div>
            <InputNumber :min="0" :max="99999999" v-model="maxAge" :inputClass="'number-input'"></InputNumber>
          </div>
        </div>
      </div>
    </div>
    <div id="kinks" class="p-d-flex p-flex-column p-ai-center">
      <h3 class="p-pt-2 p-b-1" :class="[options.nightMode ? 'night-text':'day-text' ]">Kinks</h3>
      <div class="p-d-flex p-flex-wrap p-jc-center p-ai-center">
        <KinkFilterSelect class="p-px-3 p-mt-2"
                          :kink-filter="kinkfilter"
                          :kinks="getKinks(kinkfilter.id)"
                          v-on:sectionToggle="kinkFilterToggle"
                          v-on:kinkListToggle="kinkListToggle"
                          v-on:deleteKinkFilter="deleteKinkFilter"
                          v-for="kinkfilter in selectedKinks" :key="kinkfilter.id"
        >

        </KinkFilterSelect>
        <Button
            v-if="selectedKinks.length < 10"
            icon="pi pi-plus"
            class="p-button-rounded  p-button-raised  p-mt-2"
            @click="addKinkFilter"
        />
      </div>


    </div>
    <div id="keywords" class="p-d-flex p-flex-column p-ai-center">
      <h3 class="p-pt-2 p-b-1" :class="[options.nightMode ? 'night-text':'day-text' ]">Keywords</h3>
      <div class="p-d-flex p-jc-center p-flex-wrap">
        <InputText
            v-on:keyup.enter="searchAds(0)"
            class="keywords p-mt-1"
            type="text"
            v-model="keywords"
            placeholder="Cum,Anal,Incest..."
        />
        <ToggleButton
            class="p-ml-2 p-mt-1"
            v-model="andKeywords"
            onLabel="All keywords mode"
            offLabel="Any keywords mode"
            style="width: 12em"
        />
      </div>
      <div class="p-pt-3">
        <Button
            :disabled="isSearchRunning"
            label="Search"
            icon="pi pi-search"
            iconPos="left"
            @click="searchAds(0)"
        />
        <Button
            class="p-ml-2 p-button-secondary"
            :disabled="isSearchRunning"
            label="Reset"
            icon="pi pi-backward"
            iconPos="left"
            @click="resetSearch()"
        />

        <Button
            class="p-ml-2 p-button-primary"
            :disabled="isSearchRunning"
            label="Save"
            icon="pi pi-save"
            iconPos="left"
            @click="adSearchSaveDialogVisible = true"
        />
        <template v-if="adSearchSaveDialogVisible">
          <AdSearchSaveDialog
              v-model:visibility="adSearchSaveDialogVisible"
              v-on:save="saveAdSearch($event)"
          ></AdSearchSaveDialog>
        </template>
        <Button
            class="p-ml-2 p-button-primary"
            :disabled="isSearchRunning"
            label="Load"
            icon="pi pi-file"
            iconPos="left"
            @click="adSearchLoadDialogVisible = true"
        />
        <template v-if="adSearchLoadDialogVisible">
          <AdSearchLoadDialog
              v-model:visibility="adSearchLoadDialogVisible"
              v-on:load="loadAdSearch($event)"
              v-on:delete="deleteAdSearch($event)"
              :saved-ad-searches="adSearches"
          ></AdSearchLoadDialog>
        </template>


      </div>
      <div v-if="isSearchRunning" class="keywords p-pt-2">
        <ProgressBar mode="indeterminate" style="height: 0.5em"/>
      </div>
    </div>
    <div class="p-pt-2" v-if="adSearchResponse">
      <Ad
          class="p-shadow-2"
          v-for="ad in adSearchResponse.ads"
          v-bind:key="ad.key"
          v-bind:ad="ad"
          :is-favorites="false"
          mode="adsearch"
      >
      </Ad>
    </div>
    <div class="p-d-flex p-jc-center">
      <div v-if="adSearchResponse != null && !lastAdSearchHasResults" :class="[options.nightMode ? 'night-text':'day-text']">
        Sorry I could not find anything :(
      </div>
      <div v-if="isSearchRunning && lastAdSearchHasResults" class="keywords p-pt-2">
        <ProgressBar mode="indeterminate" style="height: 0.5em"/>
      </div>
    </div>
    <div class="p-pt-2" v-if="lastAdSearchHasResults">
      <Paginator
          :class="[options.nightMode ? 'night-background':'' ]"
          :key="paginationHack"
          :rows="50"
          :totalRecords="adSearchResponse.numElements"
          v-on:page="searchAds($event.page)"
      ></Paginator>
    </div>
  </div>
</template>

<script lang="ts">
import {computed, defineComponent, ref} from "vue";
import Ad from "../components/Ad.vue";
import {AD_SEARCH, send} from "@/ts/websocket";
import {useStore} from "@/store/store";
import {useToast} from "primevue/usetoast";
import FilterMultiselect from "../components/FilterMultiselect.vue";
import {v4 as uuidv4} from "uuid";
import {AdSearch, KinkFilter, KinkFilterRequest} from "@/store/state";
import KinkFilterSelect from "@/components/KinkFilterSelect.vue";
import {Kink} from "@/ts/common";
import AdSearchSaveDialog from "@/components/AdSearchSaveDialog.vue";
import AdSearchLoadDialog from "@/components/AdSearchLoadDialog.vue";

export default defineComponent({
  name: "AdSearch",
  components: {AdSearchSaveDialog, Ad, FilterMultiselect, KinkFilterSelect, AdSearchLoadDialog},
  setup() {

    const selectedGenders = ref<Array<string>>([]);
    const selectedBodytypes = ref<Array<string>>([]);
    const selectedOrientations = ref<Array<string>>([]);
    const selectedHumanFurryPref = ref<Array<string>>([]);
    const selectedDomSub = ref<Array<string>>([]);
    const selectedLanguagePref = ref<Array<string>>([]);
    const selectedPosition = ref<Array<string>>([]);
    const selectedKinks = ref<Array<KinkFilter>>([]);
    const minAge = ref<number | null>();
    const maxAge = ref<number | null>();
    const keywords = ref<string>("");
    const store = useStore();
    const first = 0;
    const paginationHack = ref<string>(uuidv4());
    const currentCorrelationId = ref<string>(uuidv4());
    const searchRunning = ref<boolean>(false);
    const andKeywords = ref<boolean>(false);
    const adSearchSaveDialogVisible = ref<boolean>(false);
    const adSearchLoadDialogVisible = ref<boolean>(false);
    const toast = useToast();
    const kinks = store.getKinks
    const adSearches = store.getAdSearches
    const ignoreNum = store.getOptions.value.ignoreSet.size

    const availableOrientations = computed(
        () => store.getOrientations.value,
    );
    const availableBodytypes = computed(
        () => store.getBodytypes.value,
    );

    const availableGenders = computed(
        () => store.getGenders.value,
    );

    const availableFurryPref = computed(
        () => store.getFurryPref.value,
    );
    const availableSubDom = computed(
        () => store.getSubDom.value,
    );

    const availableLanguagePref = computed(
        () => store.getLanguagePref.value,
    );

    const availablePosition = computed(
        () => store.getPosition.value,
    );


    const getKinks = (id: string): Kink[] => {
      const currentSelectedKinks = selectedKinks.value.map(kink => kink.kinkIds).flatMap(kinkIds => kinkIds)
      const kinkFilterRequesting = selectedKinks.value.find(value => value.id === id)
      return kinks.value.filter(kink => (!currentSelectedKinks.includes(parseInt(kink.id))) ||
          (kinkFilterRequesting && kinkFilterRequesting.kinkIds.includes(parseInt(kink.id))))
          .sort((a, b) => {
            return a.name.localeCompare(b.name)
          })
    }

    const lastAdSearchHasResults = computed((): boolean => {
      return store.getAdSearch.value != null && store.getAdSearch.value?.ads.length > 0
    })

    const kinkListToggle = (args: { id: string; kinkIds: string[] }) => {
      const kink = selectedKinks.value.find(value => {
        return value.id == args.id
      });
      if (kink) {
        kink.kinkIds = args.kinkIds.map(value => parseInt(value))
      }
    }
    const deleteKinkFilter = (id: string) => {
      selectedKinks.value = selectedKinks.value.filter(value => value.id !== id)
    }
    const kinkFilterToggle = (args: { id: string; section: string }) => {
      const kink = selectedKinks.value.find(value => {
        return value.id == args.id
      });
      if (kink) {
        switch (args.section) {
          case "fave": {
            kink.fave = !kink.fave;
            break;
          }
          case "yes": {
            kink.yes = !kink.yes;
            break;
          }
          case "maybe": {
            kink.maybe = !kink.maybe;
            break;
          }
          case "no": {
            kink.no = !kink.no;
            break;
          }
        }
      }
    }

    const addKinkFilter = () => {
      selectedKinks.value.push({
        id: uuidv4(),
        maybe: false,
        fave: false,
        yes: false,
        no: false,
        kinkIds: []
      } as KinkFilter)
    }

    const isSearchRunning = computed((): boolean => {
      if (!searchRunning.value) {
        return false;
      } else {
        return (
            store.getAdSearch == null ||
            store.getAdSearch.value?.requestCorrelation !=
            currentCorrelationId.value
        );
      }
    });

    const resetSearch = () => {
      selectedGenders.value = [];
      selectedBodytypes.value = [];
      selectedOrientations.value = [];
      selectedHumanFurryPref.value = [];
      selectedDomSub.value = [];
      selectedLanguagePref.value = [];
      selectedPosition.value = [];
      minAge.value = null;
      maxAge.value = null;
      keywords.value = "";
      selectedKinks.value = [];
      andKeywords.value = false;
    };

    const saveAdSearch = (name: string) => {
      const adSearch = {
        id: uuidv4(),
        saveTime: new Date(),
        adSearchName: name,
        keywords: keywords.value,
        maxAge: maxAge.value,
        minAge: minAge.value,
        selectedBodytypes: selectedBodytypes.value,
        selectedDomSub: selectedDomSub.value,
        selectedGenders: selectedGenders.value,
        selectedHumanFurryPref: selectedHumanFurryPref.value,
        selectedKinks: selectedKinks.value,
        selectedLanguagePref: selectedLanguagePref.value,
        selectedOrientations: selectedOrientations.value,
        selectedPosition: selectedPosition.value,
        andKeywords: andKeywords.value
      } as AdSearch
      store.saveAdSearches(adSearch)
      toast.add({
        severity: "info",
        summary: "AdSearch saved",
        life: 3000,
      });
    }

    const loadAdSearch = (id: string) => {
      const savedAdSearch = store.getAdSearches.value.find(value => value.id === id);
      if (savedAdSearch) {
        keywords.value = savedAdSearch.keywords
        maxAge.value = savedAdSearch.maxAge
        minAge.value = savedAdSearch.minAge
        selectedBodytypes.value = savedAdSearch.selectedBodytypes
        selectedDomSub.value = savedAdSearch.selectedDomSub
        selectedGenders.value = savedAdSearch.selectedGenders
        selectedHumanFurryPref.value = savedAdSearch.selectedHumanFurryPref
        selectedKinks.value = savedAdSearch.selectedKinks
        selectedLanguagePref.value = savedAdSearch.selectedLanguagePref
        selectedOrientations.value = savedAdSearch.selectedOrientations
        selectedPosition.value = savedAdSearch.selectedPosition
        andKeywords.value = savedAdSearch.andKeywords
      }
      adSearchLoadDialogVisible.value = false;
    }
    const deleteAdSearch = (id: string) => {
      const savedAdSearch = store.getAdSearches.value.find(value => value.id === id);
      if (savedAdSearch) {
        store.deleteAdSearch(id)
      }
    }

    const kinkPreferenceFromSelectedKink = (selectedKink: KinkFilter) => {
      const preferences = []
      if (selectedKink.no) preferences.push('NO')
      if (selectedKink.yes) preferences.push('YES')
      if (selectedKink.maybe) preferences.push('MAYBE')
      if (selectedKink.fave) preferences.push('FAVE')
      return preferences
    }

    const selectedKinkToRequests = (selectedKink: KinkFilter) => {
      return selectedKink.kinkIds.map((value) => {
        return {kinkId: value, kinkPreferences: kinkPreferenceFromSelectedKink(selectedKink)} as KinkFilterRequest
      })
    }

    const isValidSelectedKink = (selectedKink: KinkFilter) => {
      return selectedKink.kinkIds.length > 0 && (selectedKink.no ||
          selectedKink.fave || selectedKink.maybe || selectedKink.yes)
    }


    const searchAds = (page: number) => {
      const requestId = uuidv4();
      currentCorrelationId.value = requestId;
      if (page == 0) {
        paginationHack.value = requestId;
      }
      send(
          AD_SEARCH,
          JSON.stringify({
            kinks: selectedKinks.value.filter(value => isValidSelectedKink(value)).map(value => selectedKinkToRequests(value)).flatMap(value => value),
            genders: availableGenders.value
                .every((v) => selectedGenders.value.includes(v))
                ? []
                : selectedGenders.value,
            bodytypes: availableBodytypes.value
                .every((v) => selectedBodytypes.value.includes(v))
                ? []
                : selectedBodytypes.value,
            orientations: availableOrientations.value
                .every((v) => selectedOrientations.value.includes(v))
                ? []
                : selectedOrientations.value,
            humanFurryPreferences: availableFurryPref.value
                .every((v) => selectedHumanFurryPref.value.includes(v))
                ? []
                : selectedHumanFurryPref.value,
            domSubs: availableSubDom.value
                .every((v) => selectedDomSub.value.includes(v))
                ? []
                : selectedDomSub.value,
            position: availablePosition.value
                .every((v) => selectedPosition.value.includes(v))
                ? []
                : selectedPosition.value,
            languagePreference: availableLanguagePref.value
                .every((v) => selectedLanguagePref.value.includes(v))
                ? []
                : selectedLanguagePref.value,
            minAge: minAge.value,
            maxAge: maxAge.value,
            keywords: keywords.value.split(",").map((s) => s.trim()),
            requestCorrelation: requestId,
            ignores: [...store.getOptions.value.ignoreSet],
            andKeywords: andKeywords.value,
            page: page,
          }),
          requestId
      );
      searchRunning.value = true;
      setTimeout(
          function (oldRequestId: string) {
            if (
                currentCorrelationId.value === oldRequestId &&
                isSearchRunning.value
            ) {
              toast.add({
                severity: "error",
                summary: "Error during Ad Search",
                detail:
                    "Sorry it took too long to find your ads. Try again later :(",
                life: 5000,
              });
              searchRunning.value = false;
            }
          },
          10000,
          requestId
      );
    };

    const options = store.getOptions
    return {
      options,
      selectedGenders,
      selectedBodytypes,
      selectedOrientations,
      selectedHumanFurryPref,
      selectedDomSub,
      selectedLanguagePref,
      selectedPosition,
      availableGenders,
      availableBodytypes,
      availableOrientations,
      availableFurryPref,
      availableSubDom,
      availableLanguagePref,
      availablePosition,
      keywords,
      searchAds,
      adSearchResponse: store.getAdSearch,
      first,
      paginationHack,
      isSearchRunning,
      resetSearch,
      andKeywords,
      lastAdSearchHasResults,
      minAge,
      maxAge,
      getKinks, kinkListToggle, deleteKinkFilter, kinkFilterToggle, addKinkFilter, selectedKinks,
      adSearchSaveDialogVisible, saveAdSearch, loadAdSearch, adSearchLoadDialogVisible, adSearches, deleteAdSearch,
      ignoreNum
    };
  },
});
</script>

<style lang="scss" scoped>
@import '@/scss/variables';
.ignorecon {
  cursor: pointer;
  font-weight: bold;
}

.ignorecon:hover {
  cursor: pointer;
  color: rgb(207, 0, 0);
}

.favecon {
  cursor: pointer;
  font-weight: bold;
}

.favecon:hover {
  cursor: pointer;
  color: rgb(21, 161, 28);
}

.keywords {
  width: 18rem;
}
</style>
