import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, resolveDirective as _resolveDirective, withDirectives as _withDirectives, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "p-d-flex p-mb-2 min-height" }
const _hoisted_2 = ["href"]
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "font-bowlby" }
const _hoisted_5 = ["href"]
const _hoisted_6 = ["onClick"]
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { class: "break-words" }
const _hoisted_9 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([[_ctx.genderClass,     _ctx.nightmodeActive ? 'nightmode-brightness':''], "p-p-2 p-m-1 border-round overflow-hidden"])
  }, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.hideAvatars)
        ? (_openBlock(), _createElementBlock("a", {
            key: 0,
            class: "avatar",
            href: _ctx.profileUrl,
            target: "_blank"
          }, [
            _createElementVNode("img", {
              src: _ctx.avatarUrl,
              alt: "avatar",
              class: "avatar"
            }, null, 8, _hoisted_3)
          ], 8, _hoisted_2))
        : _createCommentVNode("", true),
      _createElementVNode("div", {
        class: _normalizeClass(["p-d-flex p-flex-column", { 'p-ml-2': _ctx.hideAvatars }])
      }, [
        _createElementVNode("span", _hoisted_4, [
          (!_ctx.hideAvatars)
            ? (_openBlock(), _createElementBlock("a", {
                key: 0,
                href: _ctx.profileUrl,
                target: "_blank"
              }, _toDisplayString(_ctx.ad.characterName), 9, _hoisted_5))
            : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                _createTextVNode(_toDisplayString(_ctx.ad.characterName), 1)
              ], 64)),
          _withDirectives(_createElementVNode("i", {
            class: "p-pl-2 pi pi-eye-slash ignorecon",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.pushIgnore(_ctx.ad.characterName)))
          }, null, 512), [
            [
              _directive_tooltip,
              'Hide Ads/Status of this character',
              void 0,
              { top: true }
            ]
          ]),
          _createVNode(_component_router_link, {
            to: '/charsummary/'+_ctx.ad.characterName,
            custom: ""
          }, {
            default: _withCtx(({navigate}) => [
              _withDirectives(_createElementVNode("i", {
                class: "p-pl-2 pi pi-list bluecon",
                onClick: navigate
              }, null, 8, _hoisted_6), [
                [
                  _directive_tooltip,
                  'Go to Character Summary',
                  void 0,
                  { top: true }
                ]
              ])
            ]),
            _: 1
          }, 8, ["to"])
        ]),
        _createElementVNode("div", null, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.attributes, (att, index) => {
            return (_openBlock(), _createElementBlock("span", {
              key: att.attributeValue
            }, [
              (att != _ctx.noInfo)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    _createTextVNode(_toDisplayString(att), 1),
                    (index != _ctx.attributes.length - 1)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_7, " | "))
                      : _createCommentVNode("", true)
                  ], 64))
                : _createCommentVNode("", true)
            ]))
          }), 128))
        ])
      ], 2)
    ]),
    _createElementVNode("div", _hoisted_8, [
      _createElementVNode("b", null, [
        _createElementVNode("div", {
          innerHTML: _ctx.ad.parsedMessage,
          class: _normalizeClass([_ctx.hideEicons ? 'show-eicon' : 'hide-eicon'])
        }, null, 10, _hoisted_9)
      ])
    ])
  ], 2))
}