<template>
  <div>
    <div class="p-d-flex p-jc-center">
      <div>
        <h2 class="p-pb-3 p-text-center font-bowlby"  :class="[options.nightMode ? 'night-text':'day-text' ]">Character Summary</h2>
      </div>
    </div>
    <div id="characterName" class="p-d-flex p-flex-column p-ai-center">
      <div class="p-d-flex p-jc-center p-flex-wrap">
        <div class="p-mr-2">
          <InputText
              v-on:keyup.enter="fetchCharacterSummary(characterName)"
              class="characterName"
              type="text"
              v-model="characterName"
              placeholder="Character name"
          />
        </div>
        <Button
            :disabled="isSearchRunning"
            label="Search"
            icon="pi pi-search"
            iconPos="left"
            @click="fetchCharacterSummary(characterName)"
        />
      </div>
      <div v-if="isSearchRunning" class="p-pt-2">
        <ProgressBar mode="indeterminate" style="height: 0.5em; width: 18rem"/>
      </div>
    </div>
    <div
        class="p-pt-2"
        v-if="characterSummary && characterSummary.success && !isSearchRunning"
    >
      <div class="p-d-flex p-ai-center p-flex-column p-p-4">
        <CharacterSummary
            :characterName="characterSummary.characterName"
            :characterGender="characterSummary.characterGender"
            :attributes="attributes"
            :lookings="characterSummary.recentLookings"
        ></CharacterSummary>
        <span class="font-bowlby"  :class="[options.nightMode ? 'night-text':'day-text' ]">Recent status / ad activity</span>
        <div
            v-if="combinedResult.length != 0"
            class="p-d-flex p-ai-center p-flex-column"
        >
          <div
              v-for="result in combinedResult"
              :key="result.key"
              :class="[genderClass,nightmodeActive ? 'nightmode-brightness':'']"
              class="
              p-text-bold
              border-round
              p-shadow-2 p-m-1 p-p-2
              width100
              break-words
            "
          >
            <span class="result-title"
            >{{ calculateTime(result.date) }} ({{ result.type }})</span
            >
            <div
                v-html="result.text"
                :class="[hideEicons ? 'show-eicon' : 'hide-eicon']"
            ></div>
          </div>
        </div>
        <div v-else>None</div>
      </div>
    </div>
    <div class="p-pt-2" v-if="characterSummary && !characterSummary.success">
      <div class="p-d-flex p-ai-center p-flex-column p-p-4" :class="[options.nightMode ? 'night-text':'day-text']">
        Sorry I could not find anything :(
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {computed, defineComponent, ref} from "vue";
import {CHARACTER_SUMMARY, send} from "../ts/websocket";
import {useStore} from "../store/store";
import {useToast} from "primevue/usetoast";
import {v4 as uuidv4} from "uuid";
import CharacterSummary from "../components/CharacterSummary.vue";
import {normalizeGender} from "../ts/genderHelper";

class DisplayResult {
  date: Date;
  text: string;
  type: string;
  key: string;

  constructor(text: string, date: string, type: string, key: string) {
    this.text = text;
    this.date = new Date(date);
    this.type = type;
    this.key = key;
  }
}

export default defineComponent({
  name: "CharacterSummaryView",
  components: {CharacterSummary},
  props: {
    charname: {
      type: String,
      required: false,
      default: ""
    }
  },
  setup(props) {
    const characterName = ref<string>(props.charname);
    const store = useStore();
    const currentCorrelationId = ref<string>(uuidv4());
    const searchRunning = ref<boolean>(false);
    const toast = useToast();
    const timeOnly = computed(() => store.getOptions.value.timeOnly);
    const nightmodeActive = computed(() => store.getOptions.value.nightMode);
    const calculateTime = (timestamp: string) => {
      const t = timestamp ? new Date(timestamp) : new Date();
      return timeOnly.value
          ? "[" + t.toLocaleTimeString() + "]"
          : "[" + t.toLocaleTimeString() + " - " + t.toLocaleDateString() + "]";
    };
    const isSearchRunning = computed((): boolean => {
      if (!searchRunning.value) {
        return false;
      } else {
        return (
            store.getCharacterSummary == null ||
            store.getCharacterSummary.value?.cid != currentCorrelationId.value
        );
      }
    });

    const fetchCharacterSummary = (charname: string) => {
      if (!charname) {
        toast.add({
          severity: "warn",
          summary: "Please enter a character Name",
          life: 2000,
        });
        return;
      }
      const requestId = uuidv4();
      currentCorrelationId.value = requestId;
      send(
          CHARACTER_SUMMARY,
          JSON.stringify({
            characterName: charname,
            cid: requestId,
          }),
          requestId
      );
      searchRunning.value = true;
      setTimeout(
          function (oldRequestId: string) {
            if (
                currentCorrelationId.value === oldRequestId &&
                isSearchRunning.value
            ) {
              toast.add({
                severity: "error",
                summary: "Error during Summary Fetch",
                detail:
                    "Sorry it took too long to collect the summary. Try again later :(",
                life: 5000,
              });
              searchRunning.value = false;
            }
          },
          4000,
          requestId
      );
    };
    const noInfo = "No Info";

    const combinedResult = computed((): Array<DisplayResult> => {
      if (store.getCharacterSummary != null) {
        let mappedAds = store.getCharacterSummary.value?.recentAds.map((a) => {
          return new DisplayResult(a.text, a.timestamp, "Ad", a.key);
        });
        if (mappedAds == undefined) {
          mappedAds = [];
        }
        let mappedStatus = store.getCharacterSummary.value?.recentLookings.map(
            (a) => {
              return new DisplayResult(
                  a.text,
                  a.timestamp,
                  "Status",
                  a.key
              );
            }
        );
        if (mappedStatus == undefined) {
          mappedStatus = [];
        }
        const combinedResults = [...mappedAds, ...mappedStatus];
        combinedResults.sort((a: DisplayResult, b: DisplayResult): number => {
          return b.date.getTime() - a.date.getTime();
        });
        return combinedResults;
      }
      return [];
    });

    if (props.charname) {
      fetchCharacterSummary(props.charname)
    }
    const options = store.getOptions
    return {
      options,
      combinedResult,
      genderClass: computed(() =>
          normalizeGender(store.getCharacterSummary.value?.characterGender)
      ),
      calculateTime,
      characterName,
      nightmodeActive,
      fetchCharacterSummary,
      characterSummary: store.getCharacterSummary,
      isSearchRunning,
      hideEicons: computed(() => store.getOptions.value.hideEicons),
      attributes: computed(() => {
        return [
          store.getCharacterSummary.value?.characterOrientation,
          store.getCharacterSummary.value?.characterDomsub,
          store.getCharacterSummary.value?.characterFurryPreference,
        ]
            .filter((att) => att != null)
            .filter((att) => att != noInfo);
      }),
    };
  },
});
</script>

<style lang="scss" scoped>
@import '@/scss/variables';
.result-title {
  text-decoration: underline;
}
.border-round {
  border-radius: 4px;
  border: 1px solid black;
}

.width100 {
  width: 100%;
}

</style>
