<template>
  <div>
    <div>
      <MultiSelect
          :loading="kinks.length === 0"
          :modelValue="kinks.length === 0 ? [] : kinkFilter.kinkIds ? kinkFilter.kinkIds.map(value => value.toString()) : []"
          v-on:change="kinkListChange"
          :options="kinks"
          optionLabel="name"
          optionValue="id"
          :filter="true"
          :showToggleAll="false"
          placeholder="Select kinks to filter..."
          listStyle="max-height:150px"
          style="width:13rem"/>
      <i
          class="pi pi-times card-icon delete-icon"
          @click="deleteKinkFilter"
      ></i>
    </div>
    <div class="p-pt-1">
      <Checkbox v-on:change="checkboxChange('fave')" :id="'fave'+kinkFilter.id" :modelValue="kinkFilter.fave"
                :binary="true"/>
      <label class="kinkCheckboxLabel" :for="'fave'+kinkFilter.id">Fave</label>
      <Checkbox class="kinkCheckbox" v-on:change="checkboxChange('yes')" :id="'yes'+kinkFilter.id"
                :modelValue="kinkFilter.yes"
                :binary="true"/>
      <label class="kinkCheckboxLabel" :for="'yes'+kinkFilter.id">Yes</label>
      <Checkbox class="kinkCheckbox" v-on:change="checkboxChange('maybe')" :id="'maybe'+kinkFilter.id"
                :modelValue="kinkFilter.maybe"
                :binary="true"/>
      <label class="kinkCheckboxLabel" :for="'maybe'+kinkFilter.id">Mb</label>
      <Checkbox class="kinkCheckbox" v-on:change="checkboxChange('no')" :id="'no'+kinkFilter.id"
                :modelValue="kinkFilter.no" :binary="true"/>
      <label class="kinkCheckboxLabel" :for="'no'+kinkFilter.id">No</label>
    </div>


  </div>
</template>

<script lang="ts">
import {defineComponent, PropType, Slots} from "vue";
import {KinkFilter} from "@/store/state";
import {Kink} from "@/ts/common";

interface SetupContext {
  slots: Slots;
  emit: (event: string, ...args: unknown[]) => void;
}

export default defineComponent({
  name: "KinkFilterSelect",
  props: {
    kinkFilter: {
      type: Object as PropType<KinkFilter>,
      required: true
    },
    kinks: {
      type: Object as PropType<Array<Kink>>,
      required: true
    },
  },
  components: {},
  setup(props, context: SetupContext) {
    const checkboxChange = (section: string) => {
      const id = props.kinkFilter.id
      context.emit("sectionToggle", {section, id});
    };
    const kinkListChange = (args: { value: string[] }) => {
      const id = props.kinkFilter.id
      const kinkIds = args.value
      context.emit("kinkListToggle", {kinkIds, id});
    };
    const deleteKinkFilter = () => {
      const id = props.kinkFilter.id
      context.emit("deleteKinkFilter", id)
    }
    return {
      checkboxChange,
      kinkListChange,
      deleteKinkFilter,
    };
  },
});
</script>

<style scoped>
.kinkCheckbox {
  margin-left: 10px;
}

.kinkCheckboxLabel {
  margin-left: 5px;
}

.card-icon {
  cursor: pointer;
  border-radius: 5px;
  padding: 5px;
  transition: 0.3s;
}

.delete-icon {
  color: red;
}

.delete-icon:hover {
  background-color: rgb(255, 221, 221);
}
</style>