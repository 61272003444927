<template>
  <div class="background-height" :class="[options.nightMode ? 'background-color-night':'background-color']">
    <div v-if="maxConnError">
      <Message severity="error" :closable="true">Too many connections to FFA.
      </Message>
    </div>
    <Toast position="bottom-right"/>
    <NavBar></NavBar>
    <div class="p-mt-2 p-mr-2 p-ml-2">
      <router-view v-slot="{ Component }">

          <component :is="Component" />
      </router-view>
    </div>
  </div>
</template>


<script lang="ts">
import {defineComponent, onBeforeMount} from "vue";
import NavBar from "./components/NavBar.vue";
import {useStore} from "./store/store";
import {FlistClient} from "@/ts/FlistClient";

export default defineComponent({
  name: "App",
  components: {
    NavBar,
  },
  setup() {
    const store = useStore()
    onBeforeMount(async () => {
      const flistClient = new FlistClient()
      const flistMappingData = await flistClient.fetchFlistMappingData()
      store.setFlistMappingData(flistMappingData)
    })
    return {
      maxConnError: store.getMaxConnError,
      options: store.getOptions
    };
  },
});
</script>


<style lang="scss">
$night-main-bg: #1c1c1c;
$day-main-bg: rgb(241, 241, 241);

$night-text-color: white;
$day-text-color: #495057;

.nightmode-brightness{
  filter: brightness(80%);
}

.background-height{
  min-height: 100vh;
}

.background-color-night{
  background-color: $night-main-bg;
}
.background-color{
  background-color: $day-main-bg;
}

.hide-eicon img {
  display: none;
}

.show-eicon img {
  display: inline;
  vertical-align: middle;
}

* {
  padding: 0;
  margin: 0;
}

$female-base: #ff6699;
$female-end: #fa3678;
$male-base: #6699ff;
$male-end: #2e72fa;
$shemale-base: #cc66ff;
$shemale-end: #ba36fc;
$herm-base: #9b30ff;
$herm-end: #890efc;
$male-herm-base: #007fff;
$male-herm-end: #006ad4;
$transgender-base: #ee8822;
$transgender-end: #c5670a;
$cunt-boy-base: #00cc66;
$cunt-boy-end: #019b4e;

.break-words {
  white-space: pre-line;
  overflow-wrap: break-word;
  word-break: break-word;
}

.female {
  background: #fd78a5;
}

.male {
  background: #75a2fa;
}

.shemale {
  background: #c763f8;
}

.herm {
  background: #a44af8;
}

.male-herm {
  background: #1888f8;
}

.transgender {
  background: #fa9c3e;
}

.cunt-boy {
  background: #37fd9a;
}

.noinfo,
.none {
  background-color: white;
}

.font-bowlby {
  font-family: "Bowlby One SC", cursive;
}

.font-big {
  font-size: 1.5rem;
}

.eicon {
  height: 30px;
  width: 30px;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #212121;
}
</style>
