import pako from "pako"
import { Ad, Status, AdSearchResponseDto, CharacterSummaryResponseDto } from '../store/state'
import { useStore } from '../store/store'
import { enrichRawAd, enrichRawStatusDelta, getXBBEncodedMessage } from './messageHandling'
import { v4 as uuidv4 } from 'uuid';

const BULK_ADS = "BULK_ADS"
const AD = "AD"
const MAX_CONN_REACHED = "MAX_CONN_REACHED"
const STATUS_DELTA = "STATUS_DELTA"
const STATUS_FULL_LIST = "STATUS_FULL_LIST"
export const AD_SEARCH = "AD_SEARCH"
export const CHARACTER_SUMMARY = "CHARACTER_SUMMARY"
export const INITIALIZE = "INITIALIZE"
let performReconnects = true
interface Message {
    type: string;
    version: number;
    payload: string;
}

const asyncCorrelation: Map<string, string> = new Map<string, string>()
let wsConnection: WebSocket

const messageQueue: Array<Uint8Array> = []

export function send(type: string, payload: string, correlationId: string | null) {
    if (correlationId) {
        asyncCorrelation.set(type, correlationId)
    }
    const jsonMessage = JSON.stringify({
        type: type,
        version: 2.0,
        payload: payload
    })
    if (wsConnection && wsConnection.readyState == WebSocket.OPEN) {
        wsConnection.send(pako.deflate(jsonMessage))
    }else{
        messageQueue.push(pako.deflate(jsonMessage))
    }
}

export default class WebsocketConnectionHandler {
    private store = useStore()
    private enc = new TextEncoder(); // always utf-8
    private handleMessage(message: MessageEvent) {
        if (!(message.data instanceof ArrayBuffer)){
            return
        }
        const inflatedMessage = pako.inflate(new Uint8Array(message.data), { to: 'string' })
        const parsedMessage: Message = JSON.parse(inflatedMessage)
        if (parsedMessage.type === undefined) {
            return
        }
        switch (parsedMessage.type) {
            case MAX_CONN_REACHED: {
                performReconnects = false
                this.store.setMaxConnError(true)
                break;
            }
            case AD: {
                const ad: Ad = JSON.parse(parsedMessage.payload)
                const enrichedAd = enrichRawAd(ad)
                this.store.pushAd(enrichedAd)
                break
            }
            case BULK_ADS: {
                this.store.clearAds()
                const bulkAds: Array<Ad> = JSON.parse(parsedMessage.payload)
                bulkAds.forEach(ele => {
                    const ad: Ad = ele as Ad
                    const enrichedAd = enrichRawAd(ad)
                    this.store.pushAd(enrichedAd)
                })
                break
            }
            case STATUS_DELTA: {
                const statusDelta: Status = JSON.parse(parsedMessage.payload)
                if (statusDelta.deleted) {
                    this.store.deleteStatus(statusDelta.characterName)
                    break
                }
                const enrichedStatusDelta = enrichRawStatusDelta(statusDelta)
                this.store.pushStatus(enrichedStatusDelta)
                break
            }
            case STATUS_FULL_LIST: {
                const fslUncompressed: Array<Status> = JSON.parse(parsedMessage.payload)
                const enrichedStatusList = fslUncompressed.map(st => enrichRawStatusDelta(st))
                enrichedStatusList.sort((a: Status, b: Status) => new Date(b.timestamp).valueOf() - new Date(a.timestamp).valueOf())
                this.store.setStatusListInitially(enrichedStatusList)
                break
            }
            case AD_SEARCH: {
                const correlation = asyncCorrelation.get(AD_SEARCH)
                const adSearchResponse: AdSearchResponseDto = JSON.parse(parsedMessage.payload)
                if (adSearchResponse.requestCorrelation != correlation) {
                    return
                }
                adSearchResponse.ads = adSearchResponse.ads.map(e => enrichRawAd(e))
                this.store.setAdSearch(adSearchResponse)
                break
            }
            case CHARACTER_SUMMARY: {
                const correlation = asyncCorrelation.get(CHARACTER_SUMMARY)
                const characterSummary: CharacterSummaryResponseDto = JSON.parse(parsedMessage.payload)
                if (characterSummary.cid != correlation) {
                    return;
                }
                if (characterSummary.success) {
                    if (characterSummary.recentAds != null) {
                        characterSummary.recentAds.map(csa => {
                            csa.key = uuidv4()
                            csa.text = getXBBEncodedMessage(csa.text)
                        })
                    } else {
                        characterSummary.recentAds = []
                    }
                    if (characterSummary.recentLookings != null) {
                        characterSummary.recentLookings.map(csl => {
                            csl.key = uuidv4()
                            csl.text = getXBBEncodedMessage(csl.text)
                        })
                    } else {
                        characterSummary.recentLookings = []
                    }
                }


                this.store.setCharacterSummary(characterSummary)
            }
        }
    }

    handleWebsocketConnection() {
        if (!wsConnection || wsConnection.readyState != WebSocket.OPEN){
            wsConnection = new WebSocket(process.env.VUE_APP_WSG)
            wsConnection.binaryType = 'arraybuffer'
            wsConnection.onmessage = (message: MessageEvent) => {
                this.handleMessage(message)
            }
            wsConnection.onopen = () => {
                messageQueue.forEach(s => wsConnection.send(s))
            }
            wsConnection.onclose = () => {
                if (!performReconnects) return
                setTimeout(() => {
                    this.handleWebsocketConnection()
                }, 5000)
            }
        }
    }
}