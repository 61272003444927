import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "p-d-flex p-jc-center p-ai-center p-pb-2 p-pt-2" }
const _hoisted_2 = { class: "p-d-flex p-ai-center p-flex-column" }
const _hoisted_3 = { class: "p-d-flex p-ai-center" }
const _hoisted_4 = {
  class: "pi pi-info-circle p-ml-2 pointer",
  style: {"font-size":"1rem"}
}
const _hoisted_5 = {
  key: 0,
  class: "p-d-flex p-jc-center p-pt-2"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FilterList = _resolveComponent("FilterList")!
  const _component_Button = _resolveComponent("Button")!
  const _component_ToggleButton = _resolveComponent("ToggleButton")!
  const _component_Checkbox = _resolveComponent("Checkbox")!
  const _component_OverlayPanel = _resolveComponent("OverlayPanel")!
  const _component_ProgressBar = _resolveComponent("ProgressBar")!
  const _component_Ad = _resolveComponent("Ad")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_FilterList),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_Button, {
        type: "button",
        icon: "pi pi-cog",
        label: 'AdList Options',
        onClick: _ctx.toggle
      }, null, 8, ["onClick"]),
      _createVNode(_component_OverlayPanel, {
        ref: "op",
        appendTo: "body",
        showCloseIcon: false,
        id: "overlay_panel",
        style: {"width":"350px"},
        breakpoints: {'960px': '75vw'}
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_ToggleButton, {
              class: "pause-button p-mr-3",
              modelValue: _ctx.pauseAdList,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.pauseAdList) = $event)),
              onLabel: "Resume AdList",
              offLabel: "Pause AdList",
              onIcon: "pi pi-play",
              offIcon: "pi pi-pause",
              style: {"width":"15em"}
            }, null, 8, ["modelValue"]),
            _cache[3] || (_cache[3] = _createElementVNode("hr", null, null, -1)),
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_Checkbox, {
                id: "freshads",
                modelValue: _ctx.toggleFresh,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.toggleFresh) = $event)),
                binary: true
              }, null, 8, ["modelValue"]),
              _cache[2] || (_cache[2] = _createElementVNode("label", {
                class: "p-ml-2",
                for: "freshads"
              }, "Show Fresh Ads Only", -1)),
              _withDirectives(_createElementVNode("i", _hoisted_4, null, 512), [
                [
                  _directive_tooltip,
                  'Only show ads that premiered (were posted for the first time) ' +
             'at most 7 days ago. See FAQ for more information.',
                  void 0,
                  { right: true }
                ]
              ])
            ])
          ])
        ]),
        _: 1
      }, 512)
    ]),
    (_ctx.adListIsLoading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createVNode(_component_ProgressBar, {
            mode: "indeterminate",
            style: {"height":"0.5em","width":"18rem"}
          })
        ]))
      : _createCommentVNode("", true),
    (!_ctx.adListIsLoading && _ctx.getAds.length === 0)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: _normalizeClass(["p-d-flex p-jc-center p-pt-2", [_ctx.options.nightMode ? 'night-text':'day-text' ]])
        }, " Sorry there are no ads matching your filters :( ", 2))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass({ 'pause-border': _ctx.isPaused })
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getAds, (ad) => {
        return (_openBlock(), _createBlock(_component_Ad, {
          class: "p-shadow-2",
          key: ad.key,
          ad: ad,
          mode: "adlist",
          "is-favorites": false
        }, null, 8, ["ad"]))
      }), 128))
    ], 2)
  ]))
}