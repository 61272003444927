import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "p-d-flex p-jc-center" }
const _hoisted_2 = {
  key: 0,
  class: "p-text-center p-pt-2 p-d-flex p-jc-center"
}
const _hoisted_3 = { class: "p-d-flex" }
const _hoisted_4 = { class: "p-pl-4" }
const _hoisted_5 = { class: "p-pt-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputText = _resolveComponent("InputText")!
  const _component_FilterMultiselect = _resolveComponent("FilterMultiselect")!
  const _component_InputNumber = _resolveComponent("InputNumber")!
  const _component_KinkFilterSelect = _resolveComponent("KinkFilterSelect")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createBlock(_component_Dialog, {
    header: _ctx.dialogModeCreate ? 'Create Filter' : 'Update Filter',
    visible: _ctx.visibilityRef,
    "onUpdate:visible": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.visibilityRef) = $event)),
    modal: true,
    contentStyle: { overflow: 'auto' }
  }, {
    footer: _withCtx(() => [
      _createElementVNode("div", _hoisted_5, [
        (!_ctx.dialogModeCreate)
          ? (_openBlock(), _createBlock(_component_Button, {
              key: 0,
              label: "Copy",
              icon: "pi pi-copy",
              onClick: _cache[11] || (_cache[11] = ($event: any) => (_ctx.copy())),
              class: "p-button-text"
            }))
          : _createCommentVNode("", true),
        _createVNode(_component_Button, {
          label: "Cancel",
          icon: "pi pi-times",
          onClick: _cache[12] || (_cache[12] = ($event: any) => (_ctx.closeEditFilter())),
          class: "p-button-text"
        }),
        _createVNode(_component_Button, {
          label: "Save",
          icon: "pi pi-check-square",
          onClick: _cache[13] || (_cache[13] = ($event: any) => (_ctx.update())),
          class: "p-button-text",
          autofocus: ""
        })
      ])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_InputText, {
          type: "text",
          modelValue: _ctx.filterName,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.filterName) = $event)),
          placeholder: "Enter filter name"
        }, null, 8, ["modelValue"])
      ]),
      (_ctx.dialogModeCreate)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, _cache[15] || (_cache[15] = [
            _createElementVNode("span", null, [
              _createTextVNode(" Select your desired character attributes here."),
              _createElementVNode("br"),
              _createTextVNode(" Only ads / status matching your selection will be displayed. ")
            ], -1)
          ])))
        : _createCommentVNode("", true),
      _createElementVNode("div", {
        class: _normalizeClass([_ctx.screentype === 'screen' ?  'filter-grid' : 'p-d-flex p-flex-column p-ai-center'])
      }, [
        _createVNode(_component_FilterMultiselect, {
          title: 'Gender',
          availableOptions: _ctx.availableGenders,
          selectedItems: _ctx.selectedGenders,
          placeholder: 'Select genders',
          selecteditems: _ctx.selectedGenders,
          "onUpdate:selecteditems": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedGenders) = $event))
        }, null, 8, ["availableOptions", "selectedItems", "selecteditems"]),
        _createVNode(_component_FilterMultiselect, {
          title: 'Bodytype',
          availableOptions: _ctx.availableBodytypes,
          selectedItems: _ctx.selectedBodytypes,
          placeholder: 'Select bodytypes',
          selecteditems: _ctx.selectedBodytypes,
          "onUpdate:selecteditems": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.selectedBodytypes) = $event))
        }, null, 8, ["availableOptions", "selectedItems", "selecteditems"]),
        _createVNode(_component_FilterMultiselect, {
          title: 'Orientation',
          availableOptions: _ctx.availableOrientations,
          placeholder: 'Select orientations',
          selectedItems: _ctx.selectedOrientations,
          selecteditems: _ctx.selectedOrientations,
          "onUpdate:selecteditems": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.selectedOrientations) = $event))
        }, null, 8, ["availableOptions", "selectedItems", "selecteditems"]),
        _createVNode(_component_FilterMultiselect, {
          title: 'Furry Preference',
          availableOptions: _ctx.availableFurryPref,
          selectedItems: _ctx.selectedHumanFurryPref,
          placeholder: 'Select furry preference',
          selecteditems: _ctx.selectedHumanFurryPref,
          "onUpdate:selecteditems": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.selectedHumanFurryPref) = $event))
        }, null, 8, ["availableOptions", "selectedItems", "selecteditems"]),
        _createVNode(_component_FilterMultiselect, {
          title: 'Dom-Sub',
          availableOptions: _ctx.availableSubDom,
          selectedItems: _ctx.selectedDomSub,
          placeholder: 'Select dom/sub',
          selecteditems: _ctx.selectedDomSub,
          "onUpdate:selecteditems": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.selectedDomSub) = $event))
        }, null, 8, ["availableOptions", "selectedItems", "selecteditems"]),
        _createVNode(_component_FilterMultiselect, {
          title: 'Channel',
          availableOptions: _ctx.getFilterChannel().map(value => value.value),
          selectedItems: _ctx.selectedChannel,
          placeholder: 'Select channels',
          selecteditems: _ctx.selectedChannel,
          "onUpdate:selecteditems": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.selectedChannel) = $event))
        }, null, 8, ["availableOptions", "selectedItems", "selecteditems"]),
        _createVNode(_component_FilterMultiselect, {
          title: 'Preferred Language',
          availableOptions: _ctx.availableLanguagePref,
          selectedItems: _ctx.selectedPrefLanguage,
          placeholder: 'Select languages',
          selecteditems: _ctx.selectedPrefLanguage,
          "onUpdate:selecteditems": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.selectedPrefLanguage) = $event))
        }, null, 8, ["availableOptions", "selectedItems", "selecteditems"]),
        _createVNode(_component_FilterMultiselect, {
          title: 'Position',
          availableOptions: _ctx.availablePosition,
          selectedItems: _ctx.selectedPosition,
          placeholder: 'Select position',
          selecteditems: _ctx.selectedPosition,
          "onUpdate:selecteditems": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.selectedPosition) = $event))
        }, null, 8, ["availableOptions", "selectedItems", "selecteditems"]),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", null, [
            _cache[16] || (_cache[16] = _createElementVNode("div", { class: "p-py-2 p-d-flex p-jc-between p-ai-center" }, [
              _createElementVNode("h3", null, "Min Age")
            ], -1)),
            _createVNode(_component_InputNumber, {
              min: 0,
              max: 99999999,
              modelValue: _ctx.filterMinAge,
              "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.filterMinAge) = $event)),
              inputClass: 'number-input'
            }, null, 8, ["modelValue"])
          ]),
          _createElementVNode("div", _hoisted_4, [
            _cache[17] || (_cache[17] = _createElementVNode("div", { class: "p-py-2 p-d-flex p-jc-between p-ai-center" }, [
              _createElementVNode("h3", null, "Max Age")
            ], -1)),
            _createVNode(_component_InputNumber, {
              min: 0,
              max: 99999999,
              modelValue: _ctx.filterMaxAge,
              "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.filterMaxAge) = $event)),
              inputClass: 'number-input'
            }, null, 8, ["modelValue"])
          ])
        ])
      ], 2),
      _createElementVNode("div", null, [
        _createElementVNode("div", null, [
          _cache[18] || (_cache[18] = _createElementVNode("div", { class: "p-d-flex p-jc-center p-py-2" }, [
            _createElementVNode("h3", null, "Kinks")
          ], -1)),
          _createElementVNode("div", {
            class: _normalizeClass([_ctx.screentype === 'screen' ?  'filter-grid' : 'p-d-flex p-flex-column p-ai-center p-jc-center'])
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.selectedKinks, (kinkfilter) => {
              return (_openBlock(), _createBlock(_component_KinkFilterSelect, {
                "kink-filter": kinkfilter,
                kinks: _ctx.kinks,
                onSectionToggle: _ctx.kinkFilterToggle,
                onKinkListToggle: _ctx.kinkListToggle,
                onDeleteKinkFilter: _ctx.deleteKinkFilter,
                key: kinkfilter.id,
                class: _normalizeClass([_ctx.screentype === 'screen' ?  '' : 'p-mt-3'])
              }, null, 8, ["kink-filter", "kinks", "onSectionToggle", "onKinkListToggle", "onDeleteKinkFilter", "class"]))
            }), 128)),
            _createElementVNode("div", {
              class: _normalizeClass(["p-d-flex p-jc-center", [_ctx.screentype === 'screen' ?  '' : 'p-mt-3']])
            }, [
              _createVNode(_component_Button, {
                icon: "pi pi-plus",
                class: "p-button-rounded p-button-raised",
                onClick: _ctx.addKinkFilter
              }, null, 8, ["onClick"])
            ], 2)
          ], 2)
        ])
      ])
    ]),
    _: 1
  }, 8, ["header", "visible"]))
}